import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { Moment } from 'moment';
import { Observable, of, timer } from 'rxjs';
import { map, share } from 'rxjs/operators';

export interface StartStop {
  start: Moment;
  stop?: Moment;
}

export interface StartStopDuration {
  duration: number;
  stop: boolean;
}

@Pipe({ name: 'incrementDuration' })
export class IncrementDurationPipe implements PipeTransform {
  static timer = timer(0, 1000);

  transform(value: any): Observable<string> {
    if (value) {
      if (value.start) {
        if (value.stop) {
          return of(this.toDuration(value));
        } else {
          return IncrementDurationPipe.timer.pipe(
            share(),
            map(() => this.toDuration(value))
          );
        }
      } else if (value.duration) {
        if (value.stop) {
          return of(this.toFormatDuration(value));
        } else {
          return IncrementDurationPipe.timer.pipe(
            share(),
            map(() => this.toFormatDuration(value))
          );
        }
      }
    }
    return of(value);
  }

  private toDuration(startStop: StartStop): string {
    const stop = startStop.stop || moment();
    const duration = stop.diff(startStop.start) / 1000;
    return (
      Math.floor(duration / 3600) +
      ':' +
      this.pad(Math.floor((duration % 3600) / 60)) +
      ':' +
      this.pad(Math.floor(duration % 60))
    );
  }

  private toFormatDuration(startStop: StartStopDuration): string {
    const duration = startStop.duration;
    return (
      Math.floor(duration / 3600) +
      ':' +
      this.pad(Math.floor((duration % 3600) / 60)) +
      ':' +
      this.pad(Math.floor(duration % 60))
    );
  }

  private pad(value: number) {
    return value < 10 ? '0' + value : '' + value;
  }
}
