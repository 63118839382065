import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { AuthenticationService } from '@app/auth/auth.service';

@Injectable({ providedIn: 'root' })
export class ForbiddenGuard implements CanActivate {
  constructor(private authService: AuthenticationService, private router: Router) {}

  canActivate(): boolean | UrlTree {
    return (
      !!this.authService.getGrantedScopes()?.[0]?.trim() ||
      this.router.createUrlTree(['/', 'forbidden'])
    );
  }
}
