import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatChip, MatChipList } from '@angular/material/chips';
import { MatInput } from '@angular/material/input';
import { ActivatedRoute, Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Moment } from 'moment';
import { isObservable, Observable, of, Subject, timer } from 'rxjs';
import { map, startWith, take, takeUntil } from 'rxjs/operators';
import { SatDatepickerRangeValue } from 'saturn-datepicker';

import { chipInOut, chipListInOut, fadeInOut } from '../animation';
import { FilterAutocompleteOption } from './filter-autocomplete-option';
import { FilterOption } from './filter-option';
import { filterTips } from './filter-tips-animations';
import { SearchFilter } from './search-filter';

@Component({
  selector: 'app-base-filter',
  templateUrl: 'base-filter.component.html',
  styleUrls: ['base-filter.component.scss'],
  animations: [chipInOut, chipListInOut, fadeInOut, filterTips],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BaseFilterComponent implements OnDestroy, OnInit {
  @Input() searchFilter: SearchFilter;
  @Input() allowDuplicate: boolean;
  @Input() onlyTips: boolean;

  @ViewChild('stringInput', { static: false }) stringInput: MatInput;
  @ViewChild('dateInput', { static: false }) dateInput: MatInput;
  @ViewChild('rangeInput', { static: false }) rangeInput: MatInput;

  selectedInputFilter: FilterOption;
  inputValue: string;

  stringInputControl = new FormControl();
  autoCompleteOptions: Observable<FilterAutocompleteOption[]>;
  _autoCompleteOptions: FilterAutocompleteOption[];

  customRange: SatDatepickerRangeValue<Moment>;

  _tips: FilterOption[] = [];

  protected _destroyed = new Subject();
  @ViewChild('rangeList', { static: false }) protected rangeList: MatChipList;

  constructor(
    public translate: TranslateService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _route: ActivatedRoute,
    private _router: Router
  ) {}

  ngOnInit() {
    this._route.queryParams
      .pipe(takeUntil(this._destroyed), take(1))
      .subscribe(queryParams => this.parseQueryParams(queryParams));

    this.autoCompleteOptions = this.stringInputControl.valueChanges.pipe(
      takeUntil(this._destroyed),
      startWith(''),
      map(value => this._filterAutocompleteOptions(value))
    );

    if (
      this.searchFilter &&
      this.searchFilter.inputFilters &&
      this.searchFilter.inputFilters.length > 0
    ) {
      timer(0).subscribe(() => {
        this.selectedInputFilter = this.searchFilter.inputFilters[0];
        this.onSelectedInputChange();
        this._changeDetectorRef.markForCheck();
      });
    }
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  onSelectedInputChange(): void {
    if (this.searchFilter) {
      const $options =
        this.searchFilter.getInputAutoCompleteOptions(this.selectedInputFilter) || of([]);
      $options.pipe(takeUntil(this._destroyed)).subscribe(options => {
        this._autoCompleteOptions = options;
        this.stringInputControl.patchValue(this.stringInputControl.value);
        this._changeDetectorRef.markForCheck();
      });
    }
  }

  onFeatureChipClick(feature: FilterOption): void {
    if (this.searchFilter) {
      this.searchFilter.onFeatureChipClick(feature);
    }
  }

  displayOptionFn(option: FilterAutocompleteOption) {
    if (!option) {
      return '';
    }
    if (this.selectedInputFilter.type !== 'option') {
      return option.value;
    }
    if (option.translate) {
      return this.translate.instant(option.display);
    }
    return option.display;
  }

  addInputFilter(...inputs: MatInput[]) {
    if (!this.searchFilter || !this.selectedInputFilter) {
      return;
    }
    let value;
    if (this.selectedInputFilter.type === 'option') {
      value = this.stringInputControl.value;
      if (typeof value === 'string') {
        value = { display: value, value };
      }
    } else {
      for (const input of inputs) {
        if (!input.empty) {
          value = input.value;
          break;
        }
      }
    }
    if (!this.searchFilter.appliedInputFilters) {
      this.searchFilter.appliedInputFilters = [];
    }
    let inputFilter: FilterOption = this.searchFilter.appliedInputFilters.find(
      f => f.param === this.selectedInputFilter.param
    );

    if (
      inputFilter &&
      !this.allowDuplicate &&
      this.selectedInputFilter.param !== 'cpoGroup' &&
      this.selectedInputFilter.param !== 'cpo' &&
      this.selectedInputFilter.param !== 'equipmentId' &&
      this.selectedInputFilter.param !== 'evseId' &&
      this.selectedInputFilter.param !== 'emsp' &&
      this.selectedInputFilter.param !== 'location' &&
      this.selectedInputFilter.param !== 'liveStatus'
    ) {
      inputFilter.value = value;
    } else {
      inputFilter = {
        name: this.selectedInputFilter.name,
        param: this.selectedInputFilter.param,
        type: this.selectedInputFilter.type,
        value
      };

      let exist;
      if (this.selectedInputFilter.type !== 'option') {
        exist = this.searchFilter.appliedInputFilters.findIndex(
          val => val.value === inputFilter.value && val.param === inputFilter.param
        );
      } else {
        exist = this.searchFilter.appliedInputFilters.findIndex(
          val => val.value.value === inputFilter.value.value && val.param === inputFilter.param
        );
      }
      if (exist <= -1) {
        this.searchFilter.appliedInputFilters.push(inputFilter);
      }
    }

    inputs.forEach(input => (input.value = ''));
    this.stringInputControl.patchValue('');
    this._onInputFilterAdded(inputFilter);

    this._changeDetectorRef.markForCheck();
  }

  removeInputFilter(index: number) {
    if (this.searchFilter) {
      this.searchFilter.removeInputFilter(index);
      this._changeDetectorRef.markForCheck();
    }
  }

  clearInputFilters() {
    if (this.searchFilter) {
      this.searchFilter.appliedInputFilters = [];
      this._changeDetectorRef.markForCheck();
    }
  }

  onRangeChipClick(chip: MatChip) {
    if (chip.toggleSelected() && this.customRange) {
      this.customRange = undefined;
    }
  }

  onCustomRangeChange() {
    if (this.customRange && this.rangeList) {
      this.rangeList._setSelectionByValue(undefined);
    }
  }

  applyFilter() {
    if (!this.searchFilter) {
      return;
    }
    const params: any = {};
    if (this.rangeList && this.rangeList.selected) {
      const selectedChip = this.rangeList.selected as MatChip;
      params.range = selectedChip.value.param;
    }
    if (this.customRange) {
      params.range =
        this.customRange.begin.startOf('day').toISOString() +
        ',' +
        this.customRange.end.endOf('day').toISOString();
    }
    if (this.searchFilter.features) {
      this._applyFeatureFilters(params);
    }
    if (this.searchFilter.appliedInputFilters) {
      for (const inputFilter of this.searchFilter.appliedInputFilters) {
        if (inputFilter.value) {
          const type = inputFilter.type || 'string';
          switch (type) {
            case 'option':
              if (
                !params.hasOwnProperty(inputFilter.param) ||
                !(params[inputFilter.param] instanceof Array)
              ) {
                params[inputFilter.param] = [];
              }
              params[inputFilter.param].push((inputFilter.value as FilterAutocompleteOption).value);
              break;
            case 'date':
              params[inputFilter.param] = (inputFilter.value as Moment).toISOString();
              break;
            case 'dateRange':
              const range = inputFilter.value as SatDatepickerRangeValue<Moment>;
              params[inputFilter.param] =
                range.begin.startOf('day').toISOString() +
                ',' +
                range.end.endOf('day').toISOString();
              break;
            default:
              if (
                !params.hasOwnProperty(inputFilter.param) ||
                !(params[inputFilter.param] instanceof Array)
              ) {
                params[inputFilter.param] = [];
              }
              params[inputFilter.param].push(inputFilter.value);
              break;
          }
        }
      }
    }

    this._router.navigate(['.'], {
      relativeTo: this._route,
      replaceUrl: true,
      queryParams: params
    });
  }

  private _onInputFilterAdded(inputFilter: FilterOption): void {
    if (this.searchFilter) {
      this.searchFilter.onInputFilterAdded(inputFilter);
    }
  }

  private _applyFeatureFilters(params: any) {
    if (this.searchFilter && this.searchFilter.features) {
      this.searchFilter.features
        .filter(f => f.value)
        .forEach(f => this.searchFilter.applyFeatureFilter(f, params));
    }
  }

  private parseQueryParams(queryParams) {
    if (this.searchFilter) {
      this.searchFilter.appliedInputFilters = [];
    }
    for (const param in queryParams) {
      if (queryParams.hasOwnProperty(param)) {
        if (queryParams[param] instanceof Array) {
          queryParams[param].forEach(v => this.parseQueryKeyValue(param, v));
        } else {
          this.parseQueryKeyValue(param, queryParams[param]);
        }
      }
    }
  }

  private parseQueryKeyValue(key: string, value: any) {
    if (key === 'range') {
      this._parseTimeRangeFilter(value);
      return;
    }
    const featParam = this._parseFeatureParam(key, value);
    if (featParam) {
      if (this.searchFilter && this.searchFilter.features) {
        timer(0)
          .pipe(map(() => this.searchFilter.features.find(f => f.param === featParam)))
          .subscribe(feat => {
            if (feat) {
              feat.value = true;
              this._tips.push(feat);
              this._changeDetectorRef.markForCheck();
            }
          });
      }
    } else {
      this._parseInputFilter(key, value);
    }
  }

  private _parseTimeRangeFilter(value: string) {
    if (!this.searchFilter || !this.searchFilter.ranges) {
      return;
    }
    const range = this.searchFilter.ranges.find(f => f.param === value);
    if (range) {
      timer(0).subscribe(() => {
        if (this.rangeList) {
          this.rangeList._setSelectionByValue(range);
        }
        this._tips.push(Object.assign({}, range, { value: true }));
        this._changeDetectorRef.markForCheck();
      });
    } else if (value.indexOf(',') > -1) {
      const split = value.split(',');
      this.customRange = { begin: moment(split[0]), end: moment(split[1]) };
      timer(0).subscribe(() => {
        this._tips.push({
          name: 'searchFilter.Time range',
          param: 'range',
          type: 'dateRange',
          value: this.customRange
        });
      });
    }
  }

  private _parseInputFilter(key: string, value: any) {
    if (!this.searchFilter || !this.searchFilter.inputFilters) {
      return;
    }
    const inputFilter = this.searchFilter.inputFilters.find(f => f.param === key);
    if (inputFilter) {
      const type = inputFilter.type || 'string';
      let filterValue;
      switch (type) {
        case 'option':
          filterValue = this._parseOptionFilterParam(inputFilter, value);
          if (!filterValue) {
            filterValue = value;
          }
          break;
        case 'date':
          filterValue = moment(value);
          break;
        case 'dateRange':
          const range = value.split(',');
          if (range.length === 2) {
            filterValue = { begin: moment(range[0]), end: moment(range[1]) };
          }
          break;
        default:
          filterValue = value;
      }
      const $filterValue: Observable<any> = isObservable(filterValue)
        ? filterValue
        : of(filterValue);
      $filterValue.subscribe(v => {
        if (!v && type === 'option') {
          v = { display: value, value };
        }
        const applied = {
          name: inputFilter.name,
          param: inputFilter.param,
          type: inputFilter.type,
          value: v
        };

        const existFilter = this.searchFilter.appliedInputFilters.findIndex(val => {
          if (type === 'option') {
            return val.param === applied.param && val.value.value === applied.value.value;
          } else {
            return val.param === applied.param && val.value === applied.value;
          }
        });
        if (existFilter <= -1) {
          this.searchFilter.appliedInputFilters.push(applied);
        }

        this.searchFilter.appliedInputFilters.sort((a, b) => {
          if (a.param > b.param) {
            return 1;
          }
          if (a.param < b.param) {
            return -1;
          }
          return 0;
        });
        this._tips.push(applied);
        this._changeDetectorRef.markForCheck();
      });
    }
  }

  private _parseFeatureParam(key: string, value: any): string {
    if (this.searchFilter) {
      return this.searchFilter.parseFeatureParam(key, value);
    }
    return undefined;
  }

  private _filterAutocompleteOptions(value: string): FilterAutocompleteOption[] {
    if (!this._autoCompleteOptions) {
      return [];
    }
    this._autoCompleteOptions.forEach(o => {
      if (o.translate) {
        o.translateValue = this.translate.instant(o.display);
      }
    });
    let options = [];
    if (!value) {
      options = [].concat(this._autoCompleteOptions);
    } else if (typeof value === 'string') {
      value = value.toLowerCase();
      options = this._autoCompleteOptions.filter(
        o => (o.translateValue || o.display || o.value).toLowerCase().indexOf(value) > -1
      );
    }
    const sort = this.selectedInputFilter ? this.selectedInputFilter.sort : false;
    if (sort) {
      options = options.sort((a, b) => {
        a = (a.translateValue || a.display || a.value).toLowerCase();
        b = (b.translateValue || b.display || b.value).toLowerCase();
        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        return 0;
      });
    }
    return options;
  }

  private _parseOptionFilterParam(
    inputFilter: FilterOption,
    value: any
  ): FilterAutocompleteOption | Observable<FilterAutocompleteOption> {
    if (this.searchFilter) {
      return this.searchFilter.parseOptionFilterParam(inputFilter, value);
    }
  }
}
