import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { Router } from '@angular/router';

import { AppNavigationService, FabAction } from '@app/navigation';
import { TaskFilter } from '../filter/filter';

const SEARCH_BOX_PLACE_HOLDER = 'task.Search by EVSE, task type...';

@Component({
  selector: 'app-main-view',
  templateUrl: './main-view.component.html'
})
export class TaskMainViewComponent implements OnInit, OnDestroy {
  private filter: TaskFilter;
  private fabAction: FabAction = {
    name: 'New task',
    fontIcon: 'mdi-plus',
    onAction: () => this.createTask()
  };

  constructor(private router: Router, private navService: AppNavigationService, ngZone: NgZone) {
    this.filter = new TaskFilter(ngZone, navService);
  }

  ngOnInit() {
    this.navService.setAppBarSearchEnabled(true);
    this.navService.setAppBarSearchPlaceHolder(SEARCH_BOX_PLACE_HOLDER);
    this.navService.setAppBarSearchFilter(this.filter);

    this.navService.setFabAction(this.fabAction);
  }

  ngOnDestroy() {
    this.navService.setAppBarSearchEnabled(false);
    this.navService.setAppBarSearchFilter(undefined);
    this.navService.setFabAction(undefined);
  }

  private createTask() {
    this.router.navigate(['task', 'new']);
  }
}
