export type NavItemCategory = 'main' | 'emsp' | 'settings';

/**
 * Navivagion item.
 */
export interface NavItem {
  path: string;
  queryParams?: { [key: string]: string };
  scopes?: string[];
  category: NavItemCategory;
  icon?: string;
  matIcon?: string;
  svgIcon?: string;
  title: string;
  brandingLogo?: string;
  theme: string;
}

export const B2C_APP_ITEMS: NavItem[] = [
  {
    path: 'dashboard',
    scopes: ['sessions', 'admin', 'dataviz'],
    category: 'main',
    icon: 'mdi-view-dashboard-outline',
    title: 'Dashboard',
    theme: 'dashboard-theme'
  },
  {
    path: 'evse',
    scopes: ['infrastructure', 'admin'],
    category: 'main',
    svgIcon: 'ev-station-outline',
    title: 'Evse',
    theme: 'infrastructure-theme'
  },
  {
    path: 'pairing',
    scopes: ['admin', 'pairing'],
    category: 'main',
    icon: 'mdi-account-switch',
    title: 'Pairing',
    theme: 'infrastructure-theme'
  },
  {
    path: 'badge',
    scopes: ['admin', 'pairing'],
    category: 'main',
    icon: 'mdi-credit-card-multiple-outline',
    title: 'Badges',
    theme: 'infrastructure-theme'
  },
  {
    path: 'transaction',
    scopes: ['admin', 'sessions'],
    category: 'main',
    svgIcon: 'view-day-outline',
    title: 'Transactions',
    theme: 'transaction-theme'
  },
  {
    path: 'task',
    scopes: ['admin', 'task'],
    category: 'main',
    icon: 'mdi-timer-sand',
    title: 'Tasks',
    theme: 'task-theme'
  },
  {
    path: 'massFirmwareUpdate',
    scopes: ['admin', 'massFirmwareUpdate'],
    category: 'main',
    icon: 'mdi-update',
    title: 'Mass Firmware Update',
    theme: 'mass-firmware-update-theme'
  },
  {
    path: 'reporting',
    scopes: ['admin', 'reporting'],
    category: 'main',
    icon: 'mdi-clipboard-text-outline',
    title: 'Reporting',
    theme: 'reporting-theme'
  },
  {
    path: 'user',
    scopes: ['admin'],
    category: 'settings',
    svgIcon: 'group-outline',
    title: 'Users management',
    theme: 'user-theme'
  },
  {
    path: 'settings',
    category: 'settings',
    icon: 'mdi-settings-outline',
    title: 'Settings',
    theme: 'settings-theme'
  }
];
