import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatPaginatorIntl } from '@angular/material/paginator';
import {
  MAT_MOMENT_DATE_FORMATS,
  MatMomentDateModule,
  MomentDateAdapter
} from '@angular/material-moment-adapter';
import { RouterModule } from '@angular/router';

import { OAuthModule } from 'angular-oauth2-oidc';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { GridsterModule } from 'angular2gridster';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { MomentModule } from 'ngx-moment';
import {
  DateAdapter as SatDateAdapter,
  MAT_DATE_FORMATS as SAT_DATE_FORMATS,
  SatDatepickerModule
} from 'saturn-datepicker';

// Navigation
import { AnimatedMenuComponent } from './animated-menu/animated-menu.component';
import { AppMaterialModule } from './app-material.module';
// Placeholder
import { ContentContainerComponent } from './content-container/content-container.component';
// Input
import { EditableFormFieldComponent } from './editable-form-field';
import { BaseFilterComponent } from './filter/base-filter.component';
import { FilterTipsContainerComponent } from './filter/filter-tips-container.component';
// Pipes
import { IncrementDurationPipe } from './increment-duration-pipe/increment-duration.pipe';
// i18n
import { LocalePaginatorIntl } from './locale-paginator-intl/locale-paginator-intl';
import { LocaleSelectorComponent } from './locale-selector/locale-selector.component';
import { ResultCountComponent } from './result-count/result-count.component';
import { FlyingTaskBadgeDirective } from './flying-task-badge/flying-task-badge.directive';
import { LanguageSelectorComponent } from '@app/shared/language-selector/language-selector.component';
import { SnackBarMessageComponent } from '@app/shared/valid-or-error-confirmation/snack-bar-message.component';

export const DATE_FORMATS = MAT_MOMENT_DATE_FORMATS;
DATE_FORMATS.display.dateInput = 'L';

export const LOCALE_PROVIDER = (translateService: TranslateService): string => {
  return translateService.currentLang;
};

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    // Material & CDK
    AppMaterialModule,
    MatMomentDateModule,
    // 3rd Party
    OAuthModule,
    TranslateModule,
    MomentModule,
    ScrollToModule.forRoot(),
    SatDatepickerModule,
    DeviceDetectorModule.forRoot(),
    GridsterModule.forRoot()
  ],
  declarations: [
    AnimatedMenuComponent,
    BaseFilterComponent,
    ContentContainerComponent,
    FilterTipsContainerComponent,
    EditableFormFieldComponent,
    IncrementDurationPipe,
    LocaleSelectorComponent,
    ResultCountComponent,
    LanguageSelectorComponent,
    SnackBarMessageComponent,
    // Directive
    FlyingTaskBadgeDirective
  ],
  entryComponents: [FilterTipsContainerComponent, SnackBarMessageComponent],
  exports: [
    AnimatedMenuComponent,
    BaseFilterComponent,
    ContentContainerComponent,
    EditableFormFieldComponent,
    IncrementDurationPipe,
    LocaleSelectorComponent,
    ResultCountComponent,
    LanguageSelectorComponent,
    SnackBarMessageComponent,
    // Directive,
    FlyingTaskBadgeDirective,
    // Framework
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    // Material & CDK
    AppMaterialModule,
    MatMomentDateModule,
    // 3rd Party
    OAuthModule,
    TranslateModule,
    MomentModule,
    ScrollToModule,
    SatDatepickerModule,
    DeviceDetectorModule,
    GridsterModule
  ],
  providers: [
    { provide: MatPaginatorIntl, useClass: LocalePaginatorIntl, deps: [TranslateService] },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'standard' } },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { float: 'auto' } }
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [
        { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
        { provide: MAT_DATE_LOCALE, useFactory: LOCALE_PROVIDER, deps: [TranslateService] },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: SatDateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: SAT_DATE_FORMATS, useValue: DATE_FORMATS }
      ]
    };
  }
}
