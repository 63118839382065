import { Component, EventEmitter, Input, Output } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Subtask, Task } from '@app/core';
import { FlyingTaskBadgeDirective } from '@app/shared';
import { TaskDisplay, TaskService } from '../../task.service';

@Component({
  selector: 'app-task-display-content',
  templateUrl: './task-display-content.component.html',
  styleUrls: ['./task-display-content.component.scss']
})
export class TaskDisplayContentComponent {
  @Input() display: TaskDisplay;
  @Input() taskIdLink: boolean;
  @Output() taskChange = new EventEmitter();

  private _destroyed = new Subject();

  constructor(public translate: TranslateService, private taskService: TaskService) {}

  getKeyFromId(id: string) {
    return id.substring(id.indexOf('/') + 1);
  }

  retrySubtask(subtask: Subtask, flyingBadge: FlyingTaskBadgeDirective) {
    const task: Task = {
      targets: [subtask.target],
      operation: this.display.task.operation,
      params: {
        ...this.display.task.params,
        isRetry: true
      }
    };
    this.taskService
      .createTask(task)
      .pipe(takeUntil(this._destroyed))
      .subscribe(result => flyingBadge.playAnimation(result.pendingTasks));
  }
}
