import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AccessTokenDto } from './models/fetch-tokens.dto';
import { environment } from '../../../environments/environment';
import { JWTPayload } from './models/JWTPayload';

@Injectable()
export class CognitoHttpService {
  private endpoint = `${environment.apiBaseUrl}/auth`;

  constructor(private http: HttpClient) {}

  authenticate(payload: JWTPayload): Observable<void> {
    return this.http
      .post<undefined>(this.endpoint, {
        ...payload
      })
      .pipe();
  }

  refreshToken(): Observable<AccessTokenDto> {
    return this.http.get<AccessTokenDto>(`${this.endpoint}/refresh`);
  }
}
