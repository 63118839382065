import { OAuthEvent } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';

/**
 * Adapter interface to work with both angular-oauth2-oidc and cognito
 */
export abstract class AuthenticationService {
  abstract events: Observable<OAuthEvent>;
  /**
   * Used in DefaultOAuthInterceptor
   */
  abstract fetchTokenUsingPasswordFlow(username: string, password: string): Promise<object>;
  abstract getAccessToken(): string;
  abstract getGrantedScopes(): string[];
  abstract hasValidAccessToken(): boolean;
  abstract isLoggedIn(): boolean;
  abstract logOut(): void;
  abstract logOutObservable(): Observable<void>;

  abstract refreshToken(): Promise<object>;

  abstract updateTokenAndUserScope(accessToken: string, idToken: string): Observable<void>;

  abstract initUserScopeAndAccessToken(): boolean;
}
