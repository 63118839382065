import { ScrollDispatcher } from '@angular/cdk/scrolling';
import { HttpParams } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  NgZone,
  OnDestroy,
  OnInit
} from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { ActivatedRoute } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { ScrollToConfigOptions, ScrollToService } from '@nicky-lenaers/ngx-scroll-to';
import { forkJoin, Subject, timer } from 'rxjs';
import { bufferTime, debounceTime, map, takeUntil } from 'rxjs/operators';

import { Task } from '@app/core';
import { AppNavigationService } from '@app/navigation';
import { parseQueryParams } from '@app/shared';
import { AppWsStompService } from '@app/ws-notification';
import { TaskDisplay, TaskService } from '../task.service';
import { accordionAnim, taskPanel, footerAnim, noMoreFadeIn } from './accordion-animations';

const LOADING_SIZE = 10;
const MIN_LOADING_TIME = 150;

@Component({
  selector: 'app-task-accordion',
  templateUrl: 'accordion.component.html',
  styleUrls: ['accordion.component.scss'],
  animations: [accordionAnim, taskPanel, footerAnim, noMoreFadeIn],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskAccordionComponent implements AfterViewInit, OnDestroy, OnInit {
  chartWidth = 0;
  displays: TaskDisplay[] = [];

  viewInit: boolean;
  querying: boolean;
  totalCount = -1;
  locationName?: string;

  private _destroyed = new Subject();
  private queryParams: any;
  private notifications = new Subject<string>();
  private stopQuery = new Subject();

  constructor(
    public translate: TranslateService,
    private ngZone: NgZone,
    private changeDetectorRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private scrollDispatcher: ScrollDispatcher,
    private scrollToService: ScrollToService,
    private navService: AppNavigationService,
    private taskService: TaskService,
    private stompService: AppWsStompService
  ) {}

  ngOnInit() {
    this.scrollDispatcher
      .scrolled()
      .pipe(takeUntil(this._destroyed))
      .subscribe(scrollable => {
        if (scrollable) {
          const nativeEl = scrollable.getElementRef().nativeElement;
          if (nativeEl.scrollTop + nativeEl.offsetHeight > nativeEl.scrollHeight - 48) {
            this.onScrolled();
          }
        }
      });

    this.route.queryParams.pipe(takeUntil(this._destroyed), debounceTime(500)).subscribe(params => {
      this.displays = [];
      this.totalCount = -1;
      this.queryParams = params;
      this.fetchMoreTasks();
    });

    this.ngZone.runOutsideAngular(() => {
      this.notifications.pipe(takeUntil(this._destroyed), bufferTime(1000)).subscribe(taskIds => {
        if (this.queryParams.id) {
          taskIds = taskIds.filter(id => id === this.queryParams.id);
        }
        if (taskIds.length > 0) {
          this.fetchTasks(taskIds);
        }
      });

      this.stompService.connected$.pipe(takeUntil(this._destroyed)).subscribe(() => {
        this.subsribeToTaskNotification();
      });
    });

    this.navService.setAppBarProgress(true);
    this.querying = true;
    timer(1000)
      .pipe(takeUntil(this._destroyed))
      .subscribe(() =>
        this.route.queryParams
          .pipe(takeUntil(this._destroyed), debounceTime(500))
          .subscribe(params => this.onSearchParamsChanges(params))
      );
  }

  ngAfterViewInit() {
    this.viewInit = true;
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  trackByFn(_index: number, item: TaskDisplay) {
    return item.task._id;
  }

  onPanelOpened(expansionPanel: MatExpansionPanel) {
    this.scrollToPanel(expansionPanel);
  }

  onTaskUpdated(task: Task) {
    this.onTasksLoaded([task]);
  }

  onScrolled() {
    if (this.querying || this.displays.length === this.totalCount) {
      return;
    }
    this.ngZone.run(() => this.fetchMoreTasks());
  }

  private onSearchParamsChanges(params: any) {
    // Unsubscribe query ongoing and tx update subscriptions
    this.stopQuery.next();
    this.querying = false;
    this.displays = [];
    this.totalCount = -1;
    this.queryParams = params;
    this.fetchMoreTasks();
  }

  private fetchMoreTasks() {
    if (this.querying) {
      return;
    }
    this.querying = true;
    this.navService.setAppBarProgress(this.displays.length === 0);
    this.changeDetectorRef.markForCheck();

    const page = Math.floor(this.displays.length / LOADING_SIZE) + 1;
    const queryParams = Object.assign({}, parseQueryParams(this.queryParams), {
      limit: LOADING_SIZE + '',
      page: page + ''
    });
    forkJoin([
      this.taskService.getTasks(new HttpParams({ fromObject: queryParams })),
      timer(MIN_LOADING_TIME)
    ])
      .pipe(takeUntil(this._destroyed))
      .subscribe(
        res => {
          this.totalCount = res[0].totalCount;
          this.onTasksLoaded(res[0].items);
        },
        () => {
          this.querying = false;
          this.navService.setAppBarProgress(false);
          this.changeDetectorRef.markForCheck();
        },
        () => {
          this.querying = false;
          this.navService.setAppBarProgress(false);
          this.changeDetectorRef.markForCheck();
        }
      );

    this.changeDetectorRef.markForCheck();
  }

  private onTasksLoaded(tasks: Task[]) {
    const toAck: string[] = [];
    tasks.forEach(task => {
      const i = this.displays.findIndex(d => d.task._id === task._id);
      const display = this.taskService.getTaskDisplay(task);
      if (i > -1) {
        this.displays[i] = display;
      } else {
        this.displays.push(display);
      }
      if (display.finished && !task.viewed) {
        toAck.push(task._id);
      }
    });
    if (toAck.length > 0) {
      this.taskService
        .acknowledgeTask(toAck)
        .subscribe(count => this.navService.setPendingTasks(count));
    }
    this.ngZone.run(() => this.sortTasks());
  }

  private onTaskRequestedMissing(taskId: string) {
    const i = this.displays.findIndex(d => d.task._id === taskId);
    if (i > 0) {
      this.displays.splice(i, 1);
      this.totalCount--;
    }
  }

  private sortTasks() {
    this.displays.sort((a, b) => {
      if (a.task.creationDate < b.task.creationDate) {
        return 1;
      }
      if (a.task.creationDate > b.task.creationDate) {
        return -1;
      }
      return 0;
    });
    this.querying = false;
    this.navService.setAppBarProgress(false);
    this.changeDetectorRef.markForCheck();
  }

  private scrollToPanel(panel: MatExpansionPanel) {
    timer(0).subscribe(() => {
      const scrollables = this.scrollDispatcher.getAncestorScrollContainers(panel._body);
      if (scrollables && scrollables.length > 0) {
        const distance =
          (panel._body.nativeElement.offsetParent as any).offsetTop -
          scrollables[0].getElementRef().nativeElement.scrollTop;
        const config: ScrollToConfigOptions = {
          container: scrollables[0].getElementRef(),
          duration: 225,
          easing: 'easeOutCubic',
          offset: distance
        };
        this.scrollToService.scrollTo(config);
      }
    });
  }

  private fetchTasks(taskIds: string[]) {
    const chunks = [];
    const chunkLength = 3;
    for (let i = 0; i < taskIds.length; i += chunkLength) {
      chunks.push(taskIds.slice(i, i + 1));
    }
    chunks.map(idList => {
      const queryParams = Object.assign({}, parseQueryParams(this.queryParams), {
        id: idList.join(',')
      });
      this.taskService
        .getTasks(new HttpParams({ fromObject: queryParams }))
        .pipe(takeUntil(this._destroyed), takeUntil(this.stopQuery))
        .subscribe(res => {
          const tasks = res.items;
          tasks.forEach(task => {
            if (this.displays.findIndex(d => d.task._id === task._id) < 0) {
              this.totalCount++;
            }
          });
          taskIds.forEach(id => {
            if (tasks.findIndex(a => a._id === id) < 0) {
              this.onTaskRequestedMissing(id);
            }
          });
          this.onTasksLoaded(tasks);
        });
    });
  }

  private subsribeToTaskNotification() {
    const userId = this.navService.userId.getValue();
    const key = userId.substr(userId.indexOf('/') + 1);
    this.stompService
      .subscribe(`/exchange/g2smart.task-status-notification/${key}.#`)
      .pipe(
        takeUntil(this._destroyed),
        map(msg => JSON.parse(msg.body).id)
      )
      .subscribe(taskId => this.notifications.next(taskId));
  }
}
