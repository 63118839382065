export type UserProfile =
  | 'ACCOR_USER'
  | 'USER_ADMIN'
  | 'EMSP_FUNC_ADMIN'
  | 'FLEET'
  | 'SUPPORT'
  | 'SPONSOR'
  | 'INFRASTRUCTURE_MANAGER'
  | 'B2C_ADMIN';

export type LegacyUserProfile =
  | 'ROLE_MANAGER_INFRASTRUCTURE'
  | 'ROLE_USER'
  | 'ROLE_SUPERVISOR'
  | 'ROLE_OPERATOR'
  | 'ROLE_SPONSOR'
  | 'ROLE_MANAGER_ENERGY'
  | 'ROLE_MANAGER_DRIVER'
  | 'ROLE_PARTNER'
  | 'ROLE_USER_PARTNER';

export const LEGACY_PROFILES: LegacyUserProfile[] = [
  'ROLE_MANAGER_INFRASTRUCTURE',
  'ROLE_USER',
  'ROLE_SUPERVISOR',
  'ROLE_OPERATOR',
  'ROLE_SPONSOR',
  'ROLE_MANAGER_ENERGY',
  'ROLE_MANAGER_DRIVER',
  'ROLE_PARTNER',
  'ROLE_USER_PARTNER'
];
