import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User, UserGroup } from '@app/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

export interface UserApi {
  items: User[];
  totalCount: number;
}

@Injectable({ providedIn: 'root' })
export class UserService {
  private userApiUrl = `${environment.apiBaseUrl}/user`;

  constructor(private http: HttpClient) {}

  getUsers(params?: HttpParams): Observable<UserApi> {
    return this.http.get<UserApi>(this.userApiUrl, { params });
  }

  getAuthenticatedUser(): Observable<User> {
    return this.http.get<User>(`${this.userApiUrl}/authenticated-user`).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 404) {
          return of(undefined);
        }
        return throwError(error);
      })
    );
  }

  getUserByKey(key: string): Observable<User> {
    const url = `${this.userApiUrl}/${key}`;
    return this.http.get<User>(url);
  }

  getUserScope(): Observable<string> {
    return this.http.get<{ scope: string }>(`${this.userApiUrl}/scope`).pipe(map(dto => dto.scope));
  }

  updateUser(key: string, update: any): Observable<any> {
    const url = `${this.userApiUrl}/${key}`;
    return this.http.put<User>(url, update);
  }

  createUser(user: User): Observable<User> {
    const url = `${this.userApiUrl}`;
    return this.http.post<User>(url, user);
  }

  getUserGroups(key: string): Observable<UserGroup[]> {
    const url = `${this.userApiUrl}/${key}/group`;
    return this.http.get<UserGroup[]>(url);
  }

  getEligibleGroups(key: string): Observable<UserGroup[]> {
    const url = `${this.userApiUrl}/${key}/eligibleGroup`;
    return this.http.get<UserGroup[]>(url);
  }

  checkUsername(username: string): Observable<any> {
    const url = `${this.userApiUrl}/checkName`;
    return this.http.post(url, null, { params: { username } });
  }
}
