import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ChargingLocation, Cpo, EmspChannel, Evse, User, UserPreferences } from '@app/core';
import { FilterAutocompleteOption, SearchFilter } from '@app/shared';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { concatMap, filter, map, tap } from 'rxjs/operators';

import { FabAction } from './fab/fab.component';
import { ToolbarAction } from './toolbar/toolbar-action';
import { B2C_APP_ITEMS, NavItem } from './b2c-nav-item';
import { KeywordSearchAutocompleteOption } from './keyword-search/keyword-search-autocomplete-option';
import { CpoGroup } from '@app/core/infrastructure/cpo_group';
import * as fileSaver from 'file-saver';
import { Emsp } from '@app/core/infrastructure/emsp';
import { B2cEvseApi } from '@app/core/service/b2c.service';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '@app/auth/auth.service';
import { UserService } from '@app/user';

@Injectable({ providedIn: 'root' })
export class AppNavigationService {
  private _navItems = new ReplaySubject<NavItem[]>(1);
  private _mainNavItems = new ReplaySubject<NavItem[]>(1);
  private _emspNavItems = new ReplaySubject<NavItem[]>(1);
  private _settingsNavItems = new ReplaySubject<NavItem[]>(1);
  private _selectedNavItem = new BehaviorSubject<NavItem>(undefined);
  private _sidenavLockedOpened = new BehaviorSubject<boolean>(false);
  private _fabAction = new BehaviorSubject<FabAction>(undefined);
  private _pullDownToRefreshDisabled = new BehaviorSubject<boolean>(undefined);

  private _appBarTitle = new BehaviorSubject<string>(undefined);
  private _appBarSearchEnabled = new BehaviorSubject<boolean>(false);
  private _appBarProgress = new BehaviorSubject<boolean>(undefined);
  private _backUrlFallback = new BehaviorSubject<string>(undefined);
  private _appBarSearchPlaceHolder = new BehaviorSubject<string>(undefined);
  private _appBarSearchAutoOptions = new BehaviorSubject<KeywordSearchAutocompleteOption[]>(
    undefined
  );
  private _appBarSearchFilter = new BehaviorSubject<SearchFilter>(undefined);
  private _appBarAction = new BehaviorSubject<ToolbarAction[]>([]);

  private _userId = new BehaviorSubject<string>(undefined);
  private _preferences = new BehaviorSubject<UserPreferences>(undefined);
  private _groups = new BehaviorSubject<string[]>(undefined);

  private _accessTask = new BehaviorSubject<boolean>(undefined);
  private _pendingTasks = new BehaviorSubject<number>(undefined);

  private cpoApiUrl = `${environment.apiBaseUrl}/cpo`;
  private infraApiUrl = `${environment.apiBaseUrl}/infrastructure`;
  private emspApi = `${environment.apiBaseUrl}/emsp`;
  private b2cApiUrl = `${environment.apiBaseUrl}/b2cview`;

  cpoOptions: FilterAutocompleteOption[];
  cpoGroupOptions: FilterAutocompleteOption[];
  locationOptions: FilterAutocompleteOption[];
  equipmentOptions: FilterAutocompleteOption[];
  emspNetworkOptions: FilterAutocompleteOption[];
  emspOptions: FilterAutocompleteOption[];
  emspChannelOptions: FilterAutocompleteOption[];

  constructor(
    private auth: AuthenticationService,
    private ngZone: NgZone,
    private http: HttpClient,
    private router: Router,
    private userService: UserService
  ) {
    this.auth.events
      .pipe(
        filter(e => e.type === 'token_received'),
        concatMap(() => this.userService.getAuthenticatedUser()),
        tap(user => this.updateUser(user))
      )
      .subscribe();

    this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(() => {
      // Watch route change to update selected nav item
      this.updateNavItems();
    });
  }

  getB2cEvses(params?: HttpParams): Observable<B2cEvseApi> {
    return this.http.get<B2cEvseApi>(this.b2cApiUrl, { params });
  }

  getAllB2cEvses(): Observable<B2cEvseApi> {
    return this.http.get<B2cEvseApi>(this.b2cApiUrl);
  }

  getCpoList(): Observable<Cpo[]> {
    return this.http.get<Cpo[]>(this.cpoApiUrl);
  }

  getCpoGroupList(): Observable<CpoGroup[]> {
    return this.http.get<Cpo[]>(`${this.cpoApiUrl}/groups`);
  }

  getLocationHints(): Observable<ChargingLocation[]> {
    return this.http.get<ChargingLocation[]>(`${this.infraApiUrl}/locationHint`);
  }

  getEvseHints(): Observable<Evse[]> {
    return this.http.get<Evse[]>(`${this.infraApiUrl}/evseHint`);
  }

  getEmspNetworkList(): Observable<Evse[]> {
    return this.http.get<Evse[]>(`${this.infraApiUrl}/emspEmobility`);
  }

  getEmspList(): Observable<Emsp[]> {
    return this.http.get<Emsp[]>(`${this.emspApi}`);
  }

  get navItems(): Observable<NavItem[]> {
    return this._navItems;
  }

  get mainNavItems(): Observable<NavItem[]> {
    return this._mainNavItems;
  }

  get emspNavItems(): Observable<NavItem[]> {
    return this._emspNavItems;
  }

  get settingsNavItems(): Observable<NavItem[]> {
    return this._settingsNavItems;
  }

  get selectedNavItem(): Observable<NavItem> {
    return this._selectedNavItem;
  }

  get fabAction(): Observable<FabAction> {
    return this._fabAction;
  }

  get pullDownToRefreshDisabled(): Observable<boolean> {
    return this._pullDownToRefreshDisabled;
  }

  get appBarTitle(): Observable<string> {
    return this._appBarTitle;
  }

  get appBarSearchEnabled(): Observable<boolean> {
    return this._appBarSearchEnabled;
  }

  get appBarProgress(): Observable<boolean> {
    return this._appBarProgress;
  }

  get appBarSearchPlaceHolder(): Observable<string> {
    return this._appBarSearchPlaceHolder;
  }

  get appBarSearchAutoOptions(): Observable<KeywordSearchAutocompleteOption[]> {
    return this._appBarSearchAutoOptions;
  }

  get appBarSearchFilter(): Observable<SearchFilter> {
    return this._appBarSearchFilter;
  }

  get appBarAction(): Observable<ToolbarAction[]> {
    return this._appBarAction;
  }

  get backUrlFallback(): Observable<string> {
    return this._backUrlFallback;
  }

  get userId(): BehaviorSubject<string> {
    return this._userId;
  }

  get sidenavLockedOpened(): Observable<boolean> {
    return this._sidenavLockedOpened;
  }

  get userPreferences(): Observable<UserPreferences> {
    return this._preferences;
  }

  get userGroups(): BehaviorSubject<string[]> {
    return this._groups;
  }

  get accessTask(): BehaviorSubject<boolean> {
    return this._accessTask;
  }

  get pendingTasks(): BehaviorSubject<number> {
    return this._pendingTasks;
  }

  setSidenavLockedOpened(lockedOpened: boolean) {
    this._sidenavLockedOpened.next(lockedOpened);
  }

  setFabAction(fabAction: FabAction) {
    this._fabAction.next(fabAction);
  }

  setPullDownToRefreshDisabled(disabled: boolean) {
    this._pullDownToRefreshDisabled.next(disabled);
  }

  setAppBarTitle(title: string) {
    this._appBarTitle.next(title);
  }

  setAppBarSearchEnabled(show: boolean) {
    this._appBarSearchEnabled.next(show);
  }

  setAppBarProgress(enabled: boolean) {
    this._appBarProgress.next(enabled);
  }

  setAppBarSearchPlaceHolder(placeHolder: string) {
    this._appBarSearchPlaceHolder.next(placeHolder);
  }

  setAppBarSearchAutoOptions(options: KeywordSearchAutocompleteOption[]) {
    this._appBarSearchAutoOptions.next(options);
  }

  setAppBarSearchFilter(searchFilter: SearchFilter) {
    this._appBarSearchFilter.next(searchFilter);
  }

  setToolbarAction(actions: ToolbarAction[]) {
    this._appBarAction.next(actions);
  }

  setBackUrlFallback(fallback: string) {
    this._backUrlFallback.next(fallback);
  }

  setPendingTasks(pendingTasks: number) {
    this._pendingTasks.next(pendingTasks > 0 ? pendingTasks : null);
  }

  updatePreference(preferences: UserPreferences) {
    const _userId = this._userId.getValue();
    if (_userId) {
      const url = `api/${_userId}`;
      this.http.put(url, { preferences }).subscribe(() => {
        this.auth.refreshToken();
      });
    }
  }

  loadCpoGroupAutoCompleteOptions(): Observable<FilterAutocompleteOption[]> {
    return this.getCpoGroupList().pipe(
      map(cpoGroup => {
        this.cpoGroupOptions = cpoGroup.map(cp => {
          return { display: cp.name || cp._key, value: cp._key };
        });
        return this.cpoGroupOptions;
      })
    );
  }

  loadCpoAutoCompleteOptions(): Observable<FilterAutocompleteOption[]> {
    return this.getCpoList().pipe(
      map(cpoList => {
        this.cpoOptions = cpoList.map(cpo => {
          return { display: cpo.name || cpo._key, value: cpo._key };
        });
        return this.cpoOptions;
      })
    );
  }

  loadLocationAutoCompleteOptions(): Observable<FilterAutocompleteOption[]> {
    return new Observable(observer => {
      if (this.locationOptions) {
        observer.next(this.locationOptions);
        observer.complete();
      } else {
        this.ngZone.runOutsideAngular(() => {
          this.getLocationHints().subscribe(locations => {
            this.locationOptions = locations.map(loc => {
              return { display: loc.name || loc._key, value: loc._key };
            });
            this.ngZone.run(() => {
              observer.next(this.locationOptions);
              observer.complete();
            });
          });
        });
      }
    });
  }

  loadEvseAutoCompleteOptions(): Observable<FilterAutocompleteOption[]> {
    return new Observable(observer => {
      if (this.equipmentOptions) {
        observer.next(this.equipmentOptions);
        observer.complete();
      } else {
        this.ngZone.runOutsideAngular(() => {
          this.getEvseHints().subscribe(evses => {
            this.equipmentOptions = evses.map(evse => {
              return { display: evse._key, value: evse._key };
            });
            this.ngZone.run(() => {
              observer.next(this.equipmentOptions);
              observer.complete();
            });
          });
        });
      }
    });
  }

  loadEmspNetworkAutoCompleteOptions(): Observable<FilterAutocompleteOption[]> {
    return new Observable(observer => {
      if (this.emspNetworkOptions) {
        observer.next(this.emspNetworkOptions);
        observer.complete();
      } else {
        this.ngZone.runOutsideAngular(() => {
          this.getEmspNetworkList().subscribe(emspList => {
            this.emspNetworkOptions = emspList.map(emsp => {
              return { display: emsp.name || emsp._key, value: emsp._key };
            });
            this.ngZone.run(() => {
              observer.next(this.emspNetworkOptions);
              observer.complete();
            });
          });
        });
      }
    });
  }

  loadEmspAutoCompleteOptions(): Observable<FilterAutocompleteOption[]> {
    return this.getEmspList().pipe(
      map(emspList => {
        this.emspOptions = emspList.map(emsp => {
          return { display: emsp.name || emsp._key, value: emsp._key };
        });
        return this.emspOptions;
      })
    );
  }

  loadEmspChannelAutoCompleteOptions(): Observable<FilterAutocompleteOption[]> {
    return new Observable(observer => {
      if (this.emspChannelOptions) {
        observer.next(this.emspChannelOptions);
        observer.complete();
      } else {
        this.ngZone.runOutsideAngular(() => {
          this.emspChannelOptions = Object.values(EmspChannel).map(emspChannel => {
            return { display: emspChannel, value: emspChannel };
          });
          this.ngZone.run(() => {
            observer.next(this.emspChannelOptions);
            observer.complete();
          });
        });
      }
    });
  }

  downLoadFile(response: HttpResponse<ArrayBuffer>) {
    const headers = response.headers;
    const contentDisposition = headers.get('content-disposition') || '';
    const matches = /filename="([^;]+)"/gi.exec(contentDisposition);
    const fileName = (matches[1] || 'untitled').trim();

    const file = new File([response.body], fileName, {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
    fileSaver.saveAs(file);
  }

  updateNavItems(): void {
    const grantedScopesItem = this.auth.getGrantedScopes();

    let scopesUnsplited: string;
    if (
      !grantedScopesItem ||
      !(grantedScopesItem instanceof Array) ||
      (grantedScopesItem as any[]).length === 0
    ) {
      scopesUnsplited = '';
    } else {
      scopesUnsplited = (grantedScopesItem as string[])[0];
    }
    const grantedScopes = scopesUnsplited.split(/\s+/);
    const navItems = B2C_APP_ITEMS.filter(item => {
      if (!item.scopes || item.scopes.length === 0) {
        return true;
      }
      for (const scopeRequired of item.scopes) {
        if (grantedScopes.indexOf(scopeRequired) > -1) {
          return true;
        }
      }
      return false;
    });
    this._accessTask.next(navItems.findIndex(i => i.path === 'task') > -1);
    this._navItems.next(navItems);
    this._mainNavItems.next(navItems.filter(i => i.category === 'main'));
    this._emspNavItems.next(navItems.filter(i => i.category === 'emsp'));
    this._settingsNavItems.next(navItems.filter(i => i.category === 'settings'));
    this._selectedNavItem.next(navItems.find(i => this.router.url.startsWith('/' + i.path)));
  }

  updateUser(user: User): void {
    this._userId.next(user._id);
    this._groups.next(user.groups);
    this._preferences.next(user.preferences);
  }
}
