import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Task } from '@app/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export interface CreateTaskResult {
  taskId: string;
  pendingTasks: number;
}

export interface TaskApi {
  items: Task[];
  totalCount: number;
}

export interface TaskAvatar {
  color?: string;
  fontIcon?: string;
  svgIcon?: string;
  tip?: string;
}

export interface TaskDisplay {
  task: Task;
  targets: string;
  avatar: TaskAvatar;
  finished: boolean;
  failure?: string;
}

@Injectable({ providedIn: 'root' })
export class TaskService {
  private taskApiUrl = `${environment.apiBaseUrl}/b2ctask`;

  constructor(private http: HttpClient) {}

  createTask(task: any): Observable<CreateTaskResult> {
    return this.http.post<CreateTaskResult>(this.taskApiUrl, task);
  }

  getFailedMassUpdateTasks(params?: HttpParams): Observable<TaskApi> {
    const uri = `${this.taskApiUrl}/failedMassUpdate`;
    return this.http.get<TaskApi>(uri, { params });
  }

  getTasks(params?: HttpParams): Observable<TaskApi> {
    return this.http.get<TaskApi>(this.taskApiUrl, { params });
  }

  acknowledgeTask(ids: string[]): Observable<number> {
    let params = new HttpParams();
    for (const id of ids) {
      params = params.append('id', id);
    }
    return this.http
      .post<any>(`${this.taskApiUrl}/ack`, null, {
        params
      })
      .pipe(map(result => result.count));
  }

  getPendingTaskCount(): Observable<number> {
    return this.http.get<any>(`${this.taskApiUrl}/pending`).pipe(map(result => result.count));
  }

  getTaskDisplay(task: Task): TaskDisplay {
    const finished = this.isTaskFinished(task);
    const keys = task.targets.map(target => target.substring(target.indexOf('/') + 1));
    const targets = keys.join(' ');
    return {
      task,
      targets,
      finished,
      avatar: this.getAvatar(task, task.failure),
      failure: task.failure
    };
  }

  private isTaskFinished(task: Task): boolean {
    if (task.status === 'DONE' || task.status === 'FAILED') {
      return true;
    }
    return false;
  }

  private getAvatar(task: Task, failure: string): TaskAvatar {
    let color: string;
    let fontIcon: string;
    if (failure) {
      color = 'app-background-warn-default';
      switch (failure) {
        default:
          color = 'app-background-lightgreen-default';
          fontIcon = 'mdi-alert-outline';
      }
    } else if (task.status === 'DONE') {
      if (task.subtasks.length === 1) {
        if (task.subtasks[0].status === 'DONE') {
          switch (task.subtasks[0].result) {
            case 'ACCEPTED':
              color = 'app-background-lightgreen-default';
              fontIcon = 'mdi-check';
              break;
            case 'SCHEDULED':
              color = 'app-background-lightgreen-default';
              fontIcon = 'mdi-clock-outline';
              break;
            default:
              color = 'app-background-warn-default';
              fontIcon = 'mdi-cancel';
          }
        } else {
          color = 'app-background-warn-default';
          fontIcon = 'mdi-alert-outline';
        }
      } else {
        color = 'app-background-lightgreen-default';
        fontIcon = 'mdi-timer-sand';
      }
    } else if (task.status === 'FAILED' || task.status === 'CANCELLED') {
      color = 'app-background-warn-default';
      fontIcon = 'mdi-alert-outline';
    } else {
      color = 'app-background-lightgreen-default';
      fontIcon = 'mdi-timer-sand';
    }
    return { color, fontIcon };
  }

  getTaskById(_key: string): Observable<Task> {
    const url = `${this.taskApiUrl}/${_key}`;
    return this.http.get<Task>(url);
  }
}
