import { Observable } from 'rxjs';

import { FilterAutocompleteOption } from './filter-autocomplete-option';
import { FilterOption } from './filter-option';

export const DEFAULT_TIME_RANGE_OPTIONS: FilterOption[] = [
  { name: 'searchFilter.range.Today', param: 'today' },
  { name: 'searchFilter.range.This week', param: 'thisWeek' },
  { name: 'searchFilter.range.This month', param: 'thisMonth' },
  { name: 'searchFilter.range.This year', param: 'thisYear' },
  { name: 'searchFilter.range.Yesterday', param: 'yesterday' },
  { name: 'searchFilter.range.Day before yesterday', param: 'dayBeforeYesterday' },
  { name: 'searchFilter.range.Previous week', param: 'previousWeek' },
  { name: 'searchFilter.range.Previous month', param: 'previousMonth' },
  { name: 'searchFilter.range.Previous year', param: 'previousYear' },
  { name: 'searchFilter.range.Last 30 minutes', param: 'last30Minutes' },
  { name: 'searchFilter.range.Last 7 days', param: 'last7Days' },
  { name: 'searchFilter.range.Last 30 days', param: 'last30Days' },
  { name: 'searchFilter.range.Last 6 months', param: 'last6Months' },
  { name: 'searchFilter.range.Last 12 months', param: 'last12Months' }
];

export class SearchFilter {
  darkerTheme: boolean;

  inputFilters: FilterOption[];
  appliedInputFilters: FilterOption[];

  ranges = DEFAULT_TIME_RANGE_OPTIONS.map(f => Object.assign({}, f));

  features: FilterOption[];

  getInputAutoCompleteOptions(
    _selectedInputFilter: FilterOption
  ): Observable<FilterAutocompleteOption[]> {
    return undefined;
  }

  onInputFilterAdded(_filter: FilterOption): void {}

  onFeatureChipClick(_filter: FilterOption): void {}

  applyFeatureFilter(_feature: FilterOption, _params: any): void {}

  parseOptionFilterParam(
    _filter: FilterOption,
    _value: any
  ): FilterAutocompleteOption | Observable<FilterAutocompleteOption> {
    return undefined;
  }

  parseFeatureParam(_key: string, _value: any): string {
    return undefined;
  }

  removeInputFilter(index: number) {
    if (this.appliedInputFilters) {
      this.appliedInputFilters.splice(index, 1);
    }
  }

  removeInputFilterByParam(param: string) {
    if (this.appliedInputFilters) {
      const index = this.appliedInputFilters.findIndex(f => f.param === param);
      if (index > -1) {
        this.removeInputFilter(index);
      }
    }
  }

  removeFeatureFilterByParam(param: string) {
    if (this.features) {
      this.features.filter(f => f.param === param).forEach(f => (f.value = undefined));
    }
  }
}
