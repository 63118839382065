import { Component, Input, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';

import { fadeInOut, FilterTipsContainerComponent, SearchFilter } from '@app/shared';
import { searchInOut } from './keyword-search-animations';
import { KeywordSearchAutocompleteOption } from './keyword-search-autocomplete-option';
import { ReportingService } from '@app/reporting/reporting.service';
import { AppNavigationService } from '../navigation.service';

@Component({
  selector: 'app-navigation-keyword-search',
  templateUrl: 'keyword-search.component.html',
  styleUrls: ['keyword-search.component.scss'],
  animations: [fadeInOut, searchInOut]
})
export class NavigationKeywordSearchComponent implements OnInit {
  value: string;
  filterNumber: number;
  isEvUserTab = false;
  disable = true;

  @Input() placeholder: string;
  @Input() autoOptions: KeywordSearchAutocompleteOption[];
  @Input() resultCount: number;
  @Input() searchFilter: SearchFilter;

  private _destroyed = new Subject();

  private inputChanges = new Subject<string>();

  @ViewChild('filterTemplate', { static: true }) private filterTemplate: TemplateRef<any>;
  @ViewChild('filterButton', { static: false }) private filterButton: MatButton;

  private triggerHovered = new Subject();
  private filterTipsOverlay: OverlayRef;
  private filterTipsContainer: FilterTipsContainerComponent;
  private keywordRegex: RegExp;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef,
    private navService: AppNavigationService,
    private reportingService: ReportingService
  ) {
    try {
      this.keywordRegex = new RegExp(
        '[\\w\\p{General_Category=L}\\*_\\+@\\-\\.]+|"(?:\\\\"|[^"])+"',
        'gu'
      );
    } catch (err) {
      try {
        this.keywordRegex = new RegExp(
          '[\\wàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ\\*_\\+@\\-\\.]+|"(?:\\\\"|[^"])+"',
          'gu'
        );
      } catch (err) {
        this.keywordRegex = new RegExp(
          '[\\wàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ\\*_\\+@\\-\\.]+|"(?:\\\\"|[^"])+"',
          'g'
        );
      }
    }
  }

  ngOnInit() {
    this.navService.navItems.pipe(takeUntil(this._destroyed)).subscribe(items => {
      this.disable = items.find(i => i.path === 'admin')
        ? this.route.snapshot.queryParams.emsp
          ? false
          : true
        : false;
    });

    this.updateFilterNumber();
    if (this.route.snapshot.queryParams.keyword) {
      this.value = this.route.snapshot.queryParams.keyword.replace(/,/g, ' ');
    }

    this.inputChanges.pipe(takeUntil(this._destroyed)).subscribe(value => {
      const keywordParam = this.keywordToQueryParam(value);
      const params: any = Object.assign({}, this.route.snapshot.queryParams);

      if (keywordParam) {
        params.keyword = keywordParam;
      } else {
        delete params.keyword;
      }
      this.router.navigate(['.'], {
        relativeTo: this.route,
        replaceUrl: true,
        queryParams: params
      });
    });

    this.router.events
      .pipe(
        takeUntil(this._destroyed),
        filter(e => e instanceof NavigationEnd)
      )
      .subscribe(() => {
        // Clear input if query params cleared
        this.updateFilterNumber();
        if (
          this.value &&
          this.keywordToQueryParam(this.value) !== this.route.snapshot.queryParams.keyword
        ) {
          this.value = '';
        }
      });

    this.triggerHovered.pipe(takeUntil(this._destroyed)).subscribe(hovered => {
      if (hovered) {
        this.openFilterTips();
      } else {
        this.closeFilterTips();
      }
    });
  }

  onInput(value: string) {
    this.inputChanges.next(value);
  }

  clear() {
    this.value = '';
    this.onInput('');
  }

  openFilter(button?: MatButton) {
    if (button) {
      button._elementRef.nativeElement.blur();
    }
    if (this.filterTemplate) {
      this.dialog.open(this.filterTemplate, { disableClose: true });
    }
  }

  onTriggerHovered(hovered: boolean) {
    this.triggerHovered.next(hovered);
  }

  private keywordToQueryParam(keyword: string): string {
    let keywords = keyword.match(this.keywordRegex);
    if (!keywords) {
      keywords = [];
    } else if (keywords.length > 0 && keywords[0] === '') {
      keywords.shift();
    }
    if (keywords.length > 0) {
      return keywords.join(',');
    } else {
      return undefined;
    }
  }

  private updateFilterNumber() {
    this.isEvUserTab = this.router.url.indexOf('/emsp/user') > -1 ? true : false;

    let paramLength = 0;
    for (const param of Object.keys(this.route.snapshot.queryParams)) {
      if (this.route.snapshot.queryParams[param] instanceof Array) {
        paramLength += this.route.snapshot.queryParams[param].length;
      } else {
        paramLength += 1;
      }
    }

    if (this.route.snapshot.queryParams.keyword) {
      paramLength--;
    }
    this.filterNumber = paramLength;
  }

  private openFilterTips() {
    if (
      this.filterNumber <= 0 ||
      (this.filterTipsOverlay && this.filterTipsOverlay.hasAttached())
    ) {
      return;
    }
    const strategry = this.overlay
      .position()
      .flexibleConnectedTo(this.filterButton._elementRef)
      .withLockedPosition()
      .withPositions([{ originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top' }]);
    this.filterTipsOverlay = this.overlay.create({
      positionStrategy: strategry,
      panelClass: 'applied-filter-tips'
    });
    const containerPortal = new ComponentPortal(
      FilterTipsContainerComponent,
      this.viewContainerRef
    );
    const portal = new TemplatePortal(this.filterTemplate, this.viewContainerRef, {
      $implicit: true
    } as any);
    const containerRef = this.filterTipsOverlay.attach<FilterTipsContainerComponent>(
      containerPortal
    );
    this.filterTipsContainer = containerRef.instance;
    this.filterTipsContainer.attachTemplatePortal(portal);
    this.filterTipsContainer._animationStateChanged
      .pipe(
        filter(event => event.phaseName === 'done' && event.toState === 'exit'),
        take(1)
      )
      .subscribe(() => this.filterTipsOverlay.dispose());
  }

  private closeFilterTips() {
    if (this.filterTipsOverlay && this.filterTipsOverlay.hasAttached()) {
      this.filterTipsContainer._startExitAnimation();
    }
  }

  download() {
    this.reportingService.getUsersReport(this.route.snapshot.queryParams).subscribe();
  }
}
