import { NgModule } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { SharedModule, AppTranslateModule } from '@app/shared';
import { TaskBaseModule } from './base/task-base.module';
import { TaskRoutingModule } from './task-routing.module';
import { TaskMainViewComponent } from './main-view/main-view.component';
import { TaskAccordionComponent } from './accordion/accordion.component';
import { TaskCreateNewComponent } from './create-new/create-new.component';
import { MatIconRegistry } from '@angular/material/icon';

@NgModule({
  declarations: [TaskCreateNewComponent, TaskMainViewComponent, TaskAccordionComponent],
  imports: [
    SharedModule,
    AppTranslateModule.forChild(['task', 'infra']),
    TaskBaseModule,
    TaskRoutingModule
  ]
})
export class TaskModule {
  constructor(sanitizer: DomSanitizer, iconRegistry: MatIconRegistry) {
    iconRegistry.addSvgIcon(
      'wrench-off-outline',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic_wrench_off_outline.svg')
    );
  }
}
