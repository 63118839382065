import * as moment from 'moment';

export function parseQueryParams(queryParams): any {
  const params = Object.assign({}, queryParams);
  if (params.range) {
    switch (params.range) {
      case 'today':
        params.after = moment()
          .startOf('day')
          .toISOString();
        break;
      case 'thisWeek':
        params.after = moment()
          .startOf('week')
          .toISOString();
        break;
      case 'thisMonth':
        params.after = moment()
          .startOf('month')
          .toISOString();
        break;
      case 'thisYear':
        params.after = moment()
          .startOf('year')
          .toISOString();
        break;
      case 'yesterday':
        const yesterday = moment().subtract(1, 'day');
        params.after = yesterday
          .clone()
          .startOf('day')
          .toISOString();
        params.before = yesterday
          .clone()
          .endOf('day')
          .toISOString();
        break;
      case 'dayBeforeYesterday':
        const dayBeforeYest = moment().subtract(2, 'day');
        params.after = dayBeforeYest
          .clone()
          .startOf('day')
          .toISOString();
        params.before = dayBeforeYest
          .clone()
          .endOf('day')
          .toISOString();
        break;
      case 'previousWeek':
        const prevWeek = moment().subtract(1, 'week');
        params.after = prevWeek
          .clone()
          .startOf('week')
          .toISOString();
        params.before = prevWeek
          .clone()
          .endOf('week')
          .toISOString();
        break;
      case 'previousMonth':
        const prevMonth = moment().subtract(1, 'month');
        params.after = prevMonth
          .clone()
          .startOf('month')
          .toISOString();
        params.before = prevMonth
          .clone()
          .endOf('month')
          .toISOString();
        break;
      case 'previousYear':
        const prevYear = moment().subtract(1, 'year');
        params.after = prevYear
          .clone()
          .startOf('year')
          .toISOString();
        params.before = prevYear
          .clone()
          .endOf('year')
          .toISOString();
        break;
      case 'last30Minutes':
        params.after = moment()
          .subtract(30, 'minute')
          .startOf('minute')
          .toISOString();
        break;
      case 'last7Days':
        params.after = moment()
          .subtract(8, 'day')
          .startOf('day')
          .toISOString();
        params.before = moment()
          .subtract(1, 'day')
          .endOf('day')
          .toISOString();
        break;
      case 'last30Days':
        params.after = moment()
          .subtract(30, 'day')
          .startOf('day')
          .toISOString();
        break;
      case 'last6Months':
        params.after = moment()
          .subtract(6, 'month')
          .startOf('day')
          .toISOString();
        break;
      case 'last12Months':
        params.after = moment()
          .subtract(12, 'month')
          .startOf('month')
          .toISOString();
        break;
      default:
        const split = params.range.split(',');
        params.after = split[0];
        params.before = split[1];
        break;
    }
    delete params.range;
  }
  return params;
}
