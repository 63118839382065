import { NgModule } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { SharedModule, AppTranslateModule } from '@app/shared';
import { AppUpdateDialogComponent } from './update-dialog/update-dialog.component';
import { NavigationFabComponent } from './fab/fab.component';
import { NavigationMainContainerComponent } from './main-container/main-container.component';
import { NavigationKeywordSearchComponent } from './keyword-search/keyword-search.component';
import { NavigationScrollTopFabComponent } from './scroll-top-fab/scroll-top-fab.component';
import { NavigationToolbarComponent } from './toolbar/toolbar.component';
import { NavigationToolbarScrollDirective } from './toolbar/toolbar-scroll.directive';

@NgModule({
  imports: [RouterModule, SharedModule],
  declarations: [
    NavigationFabComponent,
    NavigationKeywordSearchComponent,
    NavigationMainContainerComponent,
    NavigationScrollTopFabComponent,
    NavigationToolbarComponent,
    AppUpdateDialogComponent,
    // Scroll directive
    NavigationToolbarScrollDirective
  ],
  entryComponents: [AppUpdateDialogComponent],
  exports: [NavigationMainContainerComponent]
})
export class AppNavigationModule {
  constructor(sanitizer: DomSanitizer, iconRegistry: MatIconRegistry) {
    iconRegistry.addSvgIcon(
      'accor-hotels',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic_accor_hotels.svg')
    );
    iconRegistry.addSvgIcon(
      'car-outline',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic_car_outline.svg')
    );
    iconRegistry.addSvgIcon(
      'ev-station-outline',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic_ev_station_outline.svg')
    );
    iconRegistry.addSvgIcon(
      'group-outline',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic_group_outline.svg')
    );
    iconRegistry.addSvgIcon(
      'renault-lozenge',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic_renault_lozenge.svg')
    );
    iconRegistry.addSvgIcon(
      'steering-outline',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic_steering_outline.svg')
    );
    iconRegistry.addSvgIcon(
      'view-day-outline',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic_view_day_outline.svg')
    );
  }
}
