import { Directive, ElementRef } from '@angular/core';

import { AppNavigationService } from '@app/navigation';
import { BehaviorSubject } from 'rxjs';

@Directive({
  selector: '[appFlyingTaskBadge]',
  exportAs: 'FlyingTaskBadge'
})
export class FlyingTaskBadgeDirective {
  constructor(private elementRef: ElementRef, private navService: AppNavigationService) {}

  playAnimation(pendingTasks: number) {
    const accessTask = (this.navService.accessTask as BehaviorSubject<boolean>).getValue();
    if (!accessTask) {
      return;
    }
    const lockedOpened = (this.navService.sidenavLockedOpened as BehaviorSubject<
      boolean
    >).getValue();
    const flyingTarget = lockedOpened
      ? document.getElementById('sidenav-task')
      : document.getElementById('sidenav-container');
    if (flyingTarget) {
      const startRect = this.elementRef.nativeElement.getBoundingClientRect();
      const endRect = flyingTarget.getBoundingClientRect();
      const translateX = lockedOpened
        ? endRect.left - startRect.left - 8
        : endRect.left - startRect.left + 11;
      const translateY = lockedOpened
        ? endRect.top - startRect.top - 8
        : endRect.top - startRect.top + 11;
      const distance = Math.sqrt(translateX * translateX + translateY * translateY);
      const time = distance / 1.5;

      const flyingBadge = document.createElement('div');
      flyingBadge.classList.add('app-background-accent');
      flyingBadge.style.borderRadius = '50%';
      flyingBadge.style.height = '8px';
      flyingBadge.style.left = `${startRect.left}px`;
      flyingBadge.style.opacity = `1`;
      flyingBadge.style.position = 'fixed';
      flyingBadge.style.transform = `translateY(0)`;
      flyingBadge.style.transition = `200ms cubic-bezier(0.0, 0.0, 0.2, 1)`;
      flyingBadge.style.top = `${startRect.top}px`;
      flyingBadge.style.width = '8px';
      flyingBadge.style.zIndex = '1000';
      flyingTarget.appendChild(flyingBadge);

      setTimeout(() => {
        flyingBadge.style.height = '12px';
        flyingBadge.style.left = `${startRect.left + 24}px`;
        flyingBadge.style.opacity = `1`;
        flyingBadge.style.transform = `translateY(-24px)`;
        flyingBadge.style.width = '12px';
      }, 50);
      setTimeout(() => {
        flyingBadge.style.height = lockedOpened ? '16px' : '10px';
        flyingBadge.style.left = lockedOpened ? `${endRect.left - 8}px` : `${endRect.left + 11}px`;
        flyingBadge.style.transition = `${time}ms cubic-bezier(0.4, 0.0, 1, 1), left ${time}ms linear`;
        flyingBadge.style.transform = `translateY(${translateY}px)`;
        flyingBadge.style.width = lockedOpened ? '16px' : '10px';
      }, 500);
      setTimeout(() => {
        this.navService.pendingTasks.next(pendingTasks);
        flyingTarget.removeChild(flyingBadge);
      }, 500 + time);
    }
  }
}
