import { Injectable } from '@angular/core';
import { OAuthStorage } from 'angular-oauth2-oidc';

@Injectable()
export class CognitoStorageAdapter extends OAuthStorage {
  getItem(key: string): string {
    return this[key];
  }

  removeItem(key: string): void {
    this[key] = undefined;
  }

  setItem(key: string, data: string): void {
    this[key] = data;
  }
}
