import { Injectable } from '@angular/core';
import { UserService } from '@app/user';
import { Observable, of, pipe, UnaryFunction } from 'rxjs';
import { concatMap, map, mapTo, tap } from 'rxjs/operators';
import { CognitoHttpService } from './cognito-http.service';
import { AccessToken } from './models/access-token';
import { AccessTokenDto } from './models/fetch-tokens.dto';
import { JWTPayload } from '@app/auth/cognito-authentication/models/JWTPayload';

const ACCESS_TOKEN_LOCAL_STORAGE_KEY = 'accessToken';

const USER_SCOPE_LOCAL_STORAGE_KEY = 'scopes';

@Injectable()
export class CognitoAuthenticationService {
  accessToken: AccessToken;
  accessTokenStr: string;
  userScopes: string[];

  constructor(private httpService: CognitoHttpService, private userService: UserService) {}

  isLoggedIn(): boolean {
    return !!this.accessToken;
  }

  revokeToken(): Observable<void> {
    return of(undefined).pipe(
      tap(() => {
        this.accessToken = undefined;
        this.accessTokenStr = undefined;
        localStorage.removeItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY);
        localStorage.removeItem(USER_SCOPE_LOCAL_STORAGE_KEY);
      })
    );
  }

  refreshToken(): Observable<AccessToken> {
    return this.httpService.refreshToken().pipe(this.updateAccessToken(), this.fetchUserScopes());
  }

  updateTokenAndUserScope(accessToken: string, idToken: string): Observable<AccessToken> {
    this.accessTokenStr = accessToken;
    this.accessToken = this.parseToken(accessToken);

    const paylaod = this.getPayloadFromIDToken(idToken);

    localStorage.setItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY, accessToken);
    return this.httpService.authenticate(paylaod).pipe(this.fetchUserScopes());
  }

  initUserScopeAndAccessToken(): boolean {
    this.accessTokenStr = localStorage.getItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY);
    const userScopeStr = localStorage.getItem(USER_SCOPE_LOCAL_STORAGE_KEY);
    if (!this.accessTokenStr || !userScopeStr) {
      return false;
    }

    this.accessToken = this.parseToken(this.accessTokenStr);
    this.userScopes = JSON.parse(userScopeStr);
    return true;
  }

  private updateAccessToken(): UnaryFunction<Observable<AccessTokenDto>, Observable<AccessToken>> {
    return pipe(
      tap(token => (this.accessTokenStr = token.accessToken)),
      tap(token => (this.accessToken = this.parseToken(token.accessToken))),
      mapTo(this.accessToken)
    );
  }

  private getPayloadFromIDToken(idToken: string): JWTPayload {
    const base64UrlPayload = idToken.split('.')[1];
    const base64Payload = this.mapBase64UrlToBase64(base64UrlPayload);
    const payload = JSON.parse(atob(base64Payload));

    return new JWTPayload({
      email: payload.email,
      igg: payload['custom:igg']
    });
  }

  private parseToken(accessToken: string): AccessToken {
    const base64UrlPayload = accessToken.split('.')[1];
    const base64Payload = this.mapBase64UrlToBase64(base64UrlPayload);
    const payload = JSON.parse(atob(base64Payload));
    return new AccessToken({
      ...payload,
      scope: []
    });
  }

  private mapBase64UrlToBase64(base64Url: string): string {
    return base64Url.replace(/-/g, '+').replace(/_/g, '/');
  }

  private fetchUserScopes(): UnaryFunction<
    Observable<AccessToken | void>,
    Observable<AccessToken>
  > {
    return pipe(
      concatMap(() => this.userService.getUserScope()),
      map(scope => scope?.split(' ') || []),
      tap(scopes => {
        this.userScopes = scopes;
        localStorage.setItem(USER_SCOPE_LOCAL_STORAGE_KEY, JSON.stringify(scopes));
      }),
      mapTo(this.accessToken)
    );
  }
}
