export enum StopReason {
  IdTag = 'IdTag',
  Remote = 'Remote',
  Unplug = 'Unplug',
  EVDisconnected = 'EvDisconnected',
  HardReset = 'HardReset',
  PowerLoss = 'PowerLoss',
  TagReaderError = 'TagReaderError',
  Unknown = 'Unknown'
}

export enum ChargingStatus {
  CHARGING = 'Charging',
  SUSPENDED_EV = 'Suspended Ev',
  SUSPENDED_EVSE = 'Suspended Evse'
}

export enum SessionStatus {
  LOST = 'LOST',
  RECOVERED = 'RECOVERED',
  RECOVERING = 'RECOVERING',
  STARTED = 'STARTED',
  STOPPED = 'STOPPED',
  TERMINATED = 'TERMINATED'
}

export interface ChargePeriod {
  startDate: Date;
  stopDate: Date;
  chargingTime: number;
}

export interface B2cSession {
  _id: string;
  uuid: string;
  evseId: string;
  sessionId: number;

  startIdTag: string;
  stopIdTag: string;

  lastMeterIndex: number;
  groupId: number;
  lastMeterDate: Date;
  lastUpdateDate: Date;

  meterStart: number;
  startDate: Date;

  meterStop: number;
  stopDate: Date;
  stopReason: StopReason;

  chargingStatus: ChargingStatus;
  chargingStatusDate: Date;
  status: SessionStatus;
  chargePeriodList: Array<ChargePeriod>;
}
