import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppNavigationService } from '@app/navigation';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class ReportingService {
  private reportingUrl = `${environment.apiBaseUrl}/reporting`;

  constructor(private http: HttpClient, private navService: AppNavigationService) {}

  getTxReport(params: any): Observable<HttpResponse<ArrayBuffer>> {
    const url = `${this.reportingUrl}/tx`;
    return this.http
      .get(url, {
        observe: 'response',
        responseType: 'arraybuffer',
        params
      })
      .pipe(tap(response => this.navService.downLoadFile(response)));
  }

  getReports(params: HttpParams, fileType: string): Observable<HttpResponse<ArrayBuffer>> {
    const url = `${this.reportingUrl}/${fileType}`;
    return this.http
      .get(url, {
        observe: 'response',
        responseType: 'arraybuffer',
        params
      })
      .pipe(
        tap(response => {
          this.navService.downLoadFile(response);
        })
      );
  }

  getUsersReport(params: any): Observable<HttpResponse<ArrayBuffer>> {
    const url = `${this.reportingUrl}/emsp-user`;
    return this.http
      .get(url, {
        observe: 'response',
        responseType: 'arraybuffer',
        params
      })
      .pipe(tap(response => this.navService.downLoadFile(response)));
  }

  getReferentielReport(params: any): Observable<HttpResponse<ArrayBuffer>> {
    const url = `${this.reportingUrl}/referentiel-static`;
    return this.http
      .get(url, {
        observe: 'response',
        responseType: 'arraybuffer',
        params
      })
      .pipe(tap(response => this.navService.downLoadFile(response)));
  }
}
