import { Component, OnDestroy, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { FormControl, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

import { Subject, Observable, of } from 'rxjs';
import { catchError, debounceTime, takeUntil, switchMap, map, filter } from 'rxjs/operators';
import { SearchResponse } from 'elasticsearch';

import { AppNavigationService } from '@app/navigation';
import { InfrastructureService } from '@app/infrastructure/infrastructure.service';
import { fadeInOut } from '@app/shared';
import { TaskOperation, Task } from '@app/core';
import { TaskService } from '../task.service';
import { params, targetTable } from './create-new-animations';
import { MatTable } from '@angular/material/table';
import { MatInput } from '@angular/material/input';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { chevron } from '@app/alert/base/alert-display-content/alert-display-content-animation';

import { HttpParams } from '@angular/common/http';
import { B2cEvse } from '@app/core/service/b2c.service';

interface TargetAutoCompleteOption {
  fontIcon?: string;
  svgIcon?: string;
  display: string;
  value: string;
  type: string;
  record?: any;
}

interface TargetDisplay {
  id: string;
  name: string;
  partner: string;
  status: string;
}

interface OperationParam {
  name: string;
  optional: boolean;
}

interface OperationOption {
  op: TaskOperation;
  fontIcon?: string;
  svgIcon?: string;
  params?: OperationParam[];
  extendedParams?: OperationParam[];
}

const OPERATIONS: OperationOption[] = [
  {
    op: 'ENABLE_MAINTENANCE',
    fontIcon: 'mdi-wrench-outline'
  },
  {
    op: 'DISABLE_MAINTENANCE',
    svgIcon: 'wrench-off-outline'
  },
  { op: 'HARD_RESET', fontIcon: 'mdi-power' },
  { op: 'ENABLE_RFID_READER', fontIcon: 'mdi-credit-card-outline' },
  { op: 'DISABLE_RFID_READER', fontIcon: 'mdi-credit-card-off-outline' },
  { op: 'TOGGLE_RFID_ADMIN', fontIcon: 'mdi-shield-account-outline' },
  { op: 'LED_BLINK', fontIcon: 'mdi-alarm-light-outline' },
  {
    op: 'UPDATE_FIRMWARE',
    fontIcon: 'mdi-update',
    params: [
      {
        name: 'location',
        optional: false
      }
    ],
    extendedParams: [
      {
        name: 'retrieveDate',
        optional: false
      },
      {
        name: 'retries',
        optional: true
      },
      {
        name: 'retryInterval',
        optional: true
      }
    ]
  }
];

@Component({
  selector: 'app-task-create-new',
  templateUrl: './create-new.component.html',
  styleUrls: ['./create-new.component.scss'],
  animations: [fadeInOut, targetTable, params, chevron]
})
export class TaskCreateNewComponent implements AfterViewInit, OnInit, OnDestroy {
  targetControl: FormControl;
  targetHints: Observable<TargetAutoCompleteOption[]>;
  operationControl: FormControl;
  operationOptions: OperationOption[] = OPERATIONS;
  targetDisplays: TargetDisplay[] = [];
  params: any = {};
  progress: boolean;
  afterInit: boolean;
  showExtendedParam = false;

  private targetSearch: string;
  @ViewChild(MatTable, { static: false }) private targetTable: MatTable<TargetDisplay>;
  private _destroyed = new Subject();

  constructor(
    private router: Router,
    private navService: AppNavigationService,
    private taskService: TaskService,
    fb: FormBuilder
  ) {
    this.targetControl = fb.control('');
    this.operationControl = fb.control('');
  }

  ngOnInit() {
    this.navService.setBackUrlFallback('/task');

    this.targetHints = this.targetControl.valueChanges.pipe(
      takeUntil(this._destroyed),
      filter(value => typeof value === 'string'),
      debounceTime(500),
      switchMap(value => {
        this.targetSearch = value;
        const p = new HttpParams().set('keyword', value);
        return this.navService.getB2cEvses(p).pipe(catchError(() => of({} as any)));
      }),
      map(result => this.createTargetAutoCompleteOptions(result.items))
    );
    this.clearAndInit();
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  ngAfterViewInit() {
    this.afterInit = true;
  }
  clearAndInit() {
    this.operationControl.valueChanges.subscribe((op: OperationOption) => {
      this.params = {};
      if (op.op === 'UPDATE_FIRMWARE') {
        this.params.retrieveDate = new Date();
        this.params.retries = 3;
        this.params.retryInterval = 60;
      } else if (op.op === 'GET_DIAGNOSTICS') {
        this.params.retries = 3;
        this.params.retryInterval = 60;
      }
    });
  }

  addTarget(
    targetInput: MatInput,
    targetAutoTrigger: MatAutocompleteTrigger,
    option: TargetAutoCompleteOption
  ) {
    setTimeout(() => targetAutoTrigger.openPanel());
    targetInput.value = this.targetSearch;
    const index = this.targetDisplays.findIndex(t => t.id.indexOf(option.value) > -1);

    if (index < 0) {
      switch (option.type) {
        case 'evse':
          this.targetDisplays.push({
            id: option.value,
            name: option.display,
            partner:
              option.record &&
              option.record.pairing &&
              option.record.pairing[0] &&
              option.record.pairing[0].partner
                ? option.record.pairing[0].partner
                : null,
            status:
              option.record && option.record.status && option.record.status.liveStatus
                ? option.record.status.liveStatus
                : null
          });
          this.targetTable.renderRows();
          break;
      }
    }
  }

  removeTarget(index: number) {
    this.targetDisplays.splice(index, 1);
    this.targetTable.renderRows();
  }

  clearTargets() {
    this.targetDisplays = [];
    this.targetTable.renderRows();
  }

  launch() {
    const task: Task = {
      targets: this.targetDisplays.map(d => d.id),
      operation: this.operationControl.value.op,
      params: this.params
    };
    this.progress = true;
    this.taskService
      .createTask(task)
      .pipe(takeUntil(this._destroyed))
      .subscribe(result => this.router.navigate(['task'], { queryParams: { id: result.taskId } }));
  }

  private createTargetAutoCompleteOptions(result: B2cEvse[]): TargetAutoCompleteOption[] {
    const hints: TargetAutoCompleteOption[] = [];
    result.forEach(record => {
      hints.push({
        svgIcon: 'ev-station-outline',
        display: record._id,
        value: record._id,
        type: 'evse',
        record
      });
    });

    // if (result.hits) {
    //   result.hits.hits.sort((a, b) => {
    //     return b._score - a._score;
    //   });

    //   result.hits.hits.forEach(record => {
    //     if (!hints.find(h => h.display === record._id)) {
    //       hints.push({
    //         svgIcon: 'ev-station-outline',
    //         display: record._source.name,
    //         value: record._id,
    //         type: 'evse',
    //         record
    //       });
    //     }
    //     if (!hints.find(h => h.display === record._source.station)) {
    //       hints.push({
    //         fontIcon: 'mdi-map-marker-outline',
    //         display: record._source.station,
    //         value: record._source.stationID,
    //         type: 'location'
    //       });
    //     }
    //     if (!hints.find(h => h.display === record._source.orgName)) {
    //       hints.push({
    //         fontIcon: 'mdi-domain',
    //         display: record._source.orgName,
    //         value: record._source.org,
    //         type: 'cpo'
    //       });
    //     }
    //   });
    // }
    return hints;
  }
}
