import { OverlayContainer } from '@angular/cdk/overlay';
import { Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  NgZone,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { MatSidenav, MatSidenavContent } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { ScrollToConfigOptions, ScrollToService } from '@nicky-lenaers/ngx-scroll-to';
import * as moment from 'moment-timezone';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, filter, map, takeUntil } from 'rxjs/operators';

import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { Task, UserPreferences } from '@app/core';
import { APP_VERSION } from '@app/globals';
import { AVAILABLE_LOCALES, routerFade, slideInOutFromTop } from '@app/shared';
import { TaskService } from '@app/task';
import { AppWsStompService } from '@app/ws-notification';
import { NavItem } from '../nav-item';
import { AppNavigationService } from '../navigation.service';
import { fabLeave, logoFadeIn, routerOutletTranslate } from './main-container-animations';

export const PREF_SIDENAV_LOCKED = 'sidenav-locked';

@Component({
  selector: 'app-navigation-main-container',
  templateUrl: './main-container.component.html',
  styleUrls: ['main-container.component.scss'],
  animations: [fabLeave, logoFadeIn, routerFade, routerOutletTranslate, slideInOutFromTop],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationMainContainerComponent implements OnInit {
  appVersion: string = APP_VERSION;
  outletComponent: any;
  theme: string;
  title: string;
  logo: string;
  appBarSearchEnabled: boolean;

  selectedSidenavItem: NavItem;

  sidenavLocked: boolean;
  sidenavMode: string;

  canBack: boolean;
  urlFallback: string;
  previousUrl: string;
  currentUrl: string;

  locales = AVAILABLE_LOCALES;
  prefs: UserPreferences;

  taskNotifications: Task[] = [];

  taskFailed: Task;
  taskDone: Task;

  @ViewChild('sidenav', { static: true }) private sidenav: MatSidenav;
  @ViewChild('sidenavContent', { static: true }) private sidenavContent: MatSidenavContent;
  @ViewChild('taskNotificaiton', { static: true }) private taskNotificationTemplate: TemplateRef<
    any
  >;
  private snackBarRef: MatSnackBarRef<any>;

  private _destroyed = new Subject();

  constructor(
    public sanitizer: DomSanitizer,
    public navService: AppNavigationService,
    private ngZone: NgZone,
    private changeDetectorRef: ChangeDetectorRef,
    private overlayContainer: OverlayContainer,
    private snackBar: MatSnackBar,
    private media: MediaObserver,
    private location: Location,
    private router: Router,
    private translate: TranslateService,
    private scrollToService: ScrollToService,
    private stompService: AppWsStompService,
    private taskService: TaskService
  ) {
    this.router.events
      .pipe(
        takeUntil(this._destroyed),
        filter(e => e instanceof NavigationEnd)
      )
      .subscribe((event: NavigationEnd) => {
        // Watch route change to update selected sidenav item
        this.onNavigationEnd(event);
      });

    this.navService.selectedNavItem.pipe(takeUntil(this._destroyed)).subscribe(item => {
      this.selectedSidenavItem = item;
      this.updateSidenav();
    });

    this.navService.backUrlFallback
      .pipe(takeUntil(this._destroyed), debounceTime(250))
      .subscribe(fallback => {
        this.urlFallback = fallback;
        this.canBack = fallback !== undefined;
        this.changeDetectorRef.markForCheck();
      });

    this.translate.onLangChange
      .pipe(takeUntil(this._destroyed))
      .subscribe(() => this.onLangChange());

    this.media
      .asObservable()
      .pipe(takeUntil(this._destroyed))
      .subscribe(() => this.updateSidenav());

    this.navService.userPreferences
      .pipe(takeUntil(this._destroyed))
      .subscribe(prefs => this.onPreferencesChange(prefs));

    this.navService.appBarSearchEnabled
      .pipe(takeUntil(this._destroyed))
      .subscribe(enabled => (this.appBarSearchEnabled = enabled));

    this.navService.userId
      .pipe(takeUntil(this._destroyed))
      .subscribe(userId => this.subsribeToTasks(userId));

    this.taskService
      .getPendingTaskCount()
      .pipe(takeUntil(this._destroyed))
      .subscribe(count => this.navService.setPendingTasks(count));
  }

  ngOnInit() {
    setTimeout(() => {
      const drawerPref = localStorage.getItem(PREF_SIDENAV_LOCKED);
      const shouldOpen = this.media.isActive('gt-md') && (!drawerPref || drawerPref === 'true');

      if (shouldOpen) {
        this.openSidenav();
      }
    }, 200);
  }

  onActivate(event: any) {
    this.scrollToTop();
    this.outletComponent = event;
    this.changeDetectorRef.markForCheck();
  }

  back() {
    if (this.previousUrl) {
      this.location.back();
    } else if (this.urlFallback) {
      this.router.navigateByUrl(this.urlFallback);
    }
  }

  onSidenavItemClick(e: MouseEvent) {
    if ((e.button !== 0 && e.button !== 1) || e.metaKey || e.ctrlKey) {
      return;
    }
    if (this.sidenavMode === 'over') {
      this.sidenav.close();
    }
  }

  openSidenav() {
    this.sidenav.open();
    this.changeDetectorRef.markForCheck();
  }

  closeSidenav() {
    this.sidenav.close();
    this.changeDetectorRef.markForCheck();
  }

  onSidenavOpen() {
    this.sidenavLocked = this.sidenavMode === 'side';
    localStorage.setItem(PREF_SIDENAV_LOCKED, 'true');
    this.navService.setSidenavLockedOpened(this.sidenavMode === 'side');
    this.changeDetectorRef.markForCheck();
  }

  onSidenavClose() {
    this.sidenavLocked = false;
    localStorage.setItem(PREF_SIDENAV_LOCKED, 'false');
    this.navService.setSidenavLockedOpened(false);
    this.changeDetectorRef.markForCheck();
  }

  onLangSelectChange(locale: string) {
    this.navService.updatePreference({ language: locale });
  }

  getTaskAnchor(task: Task) {
    const hasAccessTask = (this.navService.accessTask as BehaviorSubject<boolean>).getValue();
    const transOperation = this.translate.instant(`notification.operation.${task.operation}`);
    const taskIdURL = hasAccessTask ? `<a href="/task?id=${task._id}">${task._id}</a>` : task._id;
    return `« ${transOperation} » ${taskIdURL}`;
  }

  getTasksAnchor(tasksLabel: string) {
    const hasAccessTask = (this.navService.accessTask as BehaviorSubject<boolean>).getValue();
    return hasAccessTask ? `<a href="/task">${tasksLabel}</a>` : tasksLabel;
  }

  dismissSnackBar() {
    if (this.snackBarRef) {
      this.snackBarRef.dismiss();
    }
  }

  onNotificationClick(event: Event) {
    if ((event.target as any).href) {
      this.router.navigateByUrl((event.target as any).getAttribute('href'));
      event.preventDefault();
      this.dismissSnackBar();
    }
  }

  private onPreferencesChange(prefs: UserPreferences) {
    this.prefs = prefs;

    if (prefs) {
      if (prefs.language) {
        this.translate.use(prefs.language);
      }
      if (prefs.timeZone) {
        moment.tz.setDefault(prefs.timeZone);
      }
    }
    this.updateSidenav();
  }

  /**
   * Sidenav mode set to 'side' when in large screen.
   */
  private updateSidenav() {
    const gtSm = this.media.isActive('gt-sm');
    const wasSide = this.sidenavMode === 'side';
    this.sidenavMode = gtSm ? 'side' : 'over';
    if (wasSide && this.sidenavMode === 'over') {
      this.closeSidenav();
    }

    this.sidenavMode = this.media.isActive('gt-sm') ? 'side' : 'over';

    if (this.selectedSidenavItem) {
      this.overlayContainer.getContainerElement().classList.remove(this.theme);
      this.theme =
        this.prefs && this.prefs.theme ? this.prefs.theme : this.selectedSidenavItem.theme;
      // this.theme = this.theme.replace('-theme', '-dark-theme');
      this.overlayContainer.getContainerElement().classList.add(this.theme);
      this.logo = this.selectedSidenavItem.brandingLogo;
      this.title = this.translate.instant(`sidenav.${this.selectedSidenavItem.title}`);
    }
  }

  private onNavigationEnd(event: NavigationEnd) {
    this.previousUrl = this.currentUrl;
    this.currentUrl = event.url;
  }

  private onLangChange() {
    if (this.selectedSidenavItem) {
      this.title = this.translate.instant(`sidenav.${this.selectedSidenavItem.title}`);
    }
  }

  private scrollToTop() {
    if (this.sidenavContent) {
      const distance = this.sidenavContent.getElementRef().nativeElement.scrollTop;
      let duration = distance / 20;
      if (duration < 150) {
        duration = 150;
      }
      if (duration > 375) {
        duration = 375;
      }
      const config: ScrollToConfigOptions = {
        container: this.sidenavContent.getElementRef(),
        duration,
        easing: 'easeOutCubic',
        offset: -distance
      };
      this.scrollToService.scrollTo(config);
    }
  }

  private subsribeToTasks(userId: string) {
    const userKey = userId.substring(userId.indexOf('/') + 1);
    this.stompService
      .subscribe(`/exchange/g2smart.task-status-notification/${userKey}.#`)
      .pipe(
        takeUntil(this._destroyed),
        map(msg => msg.body)
      )
      .subscribe(message => {
        const notification = JSON.parse(message);
        this.taskService
          .getTaskById(notification.id)
          .pipe(takeUntil(this._destroyed))
          .subscribe(task => {
            this.ngZone.run(() => this.showTaskNotification(task));
          });
      });
  }

  private showTaskNotification(task: Task) {
    this.taskNotifications.push(task);

    this.taskFailed = this.taskNotifications.find(
      t =>
        t.subtasks.findIndex(
          subTask => subTask.status !== 'DONE' || (subTask.result && subTask.result !== 'ACCEPTED')
        ) > -1
    );
    this.taskDone = this.taskNotifications.find(
      t =>
        t.subtasks.findIndex(
          subTask => subTask.status === 'DONE' && subTask.result && subTask.result === 'ACCEPTED'
        ) > -1
    );
    const backgroundClass =
      this.taskFailed && this.taskDone
        ? 'app-background-card'
        : this.taskFailed
        ? 'app-background-pink-lighter'
        : 'app-background-green-lighter';

    this.snackBarRef = this.snackBar.openFromTemplate(this.taskNotificationTemplate, {
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: ['app-notification-container', backgroundClass]
    });

    if (this.taskNotifications.length <= 0) {
      this.snackBarRef
        .afterDismissed()
        .pipe(takeUntil(this._destroyed))
        .subscribe(() => {
          this.taskNotifications = [];
          this.snackBarRef = undefined;
        });
    }
  }
}
