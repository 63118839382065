import { Location } from '@angular/common';
import { Injectable, isDevMode, NgZone } from '@angular/core';
import { AppNavigationService } from '@app/navigation';
import { StompRService, StompState } from '@stomp/ng2-stompjs';
import { filter } from 'rxjs/operators';
import { AuthenticationService } from '@app/auth/auth.service';

const MAX_ERROR_RETRY = 3;

@Injectable({ providedIn: 'root' })
export class AppWsStompService extends StompRService {
  private errorCount = 0;

  constructor(
    private ngZone: NgZone,
    private location: Location,
    private oauth2: AuthenticationService,
    private navService: AppNavigationService
  ) {
    super();
  }

  init() {
    this.ngZone.runOutsideAngular(() => {
      this.navService.userId
        .pipe(filter(value => value !== undefined))
        .subscribe(() => this.configureAndConnect());

      this.oauth2.events.pipe(filter(e => e.type === 'logout')).subscribe(() => this.disconnect());

      this.errorSubject.subscribe(() => {
        this.errorCount++;
        if (this.errorCount > MAX_ERROR_RETRY) {
          console.error('[WsStomp] Too many errors, disconnect');
          this.disconnect();
        }
      });
    });
  }

  private configureAndConnect() {
    const scheme = window.location.protocol === 'https:' ? 'wss' : 'ws';
    const url = `${scheme}://${window.location.host}${this.location.prepareExternalUrl('ws')}`;
    this.config = {
      url,
      headers: {
        login: this.navService.userId.getValue(),
        passcode: this.oauth2.getAccessToken(),
        host: 'g2smart'
      },
      heartbeat_in: 0,
      heartbeat_out: 30000,
      reconnect_delay: 3000,
      debug: isDevMode()
    };

    if (
      this.state.getValue() !== StompState.CONNECTED &&
      this.state.getValue() !== StompState.TRYING
    ) {
      this.initAndConnect();
    }
  }
}
