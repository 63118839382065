import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { NavigationGuard, ScopeGuard, TranslationGuard } from './navigation';
import { NavigationMainContainerComponent } from './navigation/main-container/main-container.component';

import { AppComponent } from './app.component';
import { ForbiddenGuard } from '@app/core/guards/forbidden.guard';

const ROUTES: Routes = [
  {
    path: '',
    component: AppComponent,
    canActivate: [TranslationGuard],
    children: [
      {
        path: 'login',
        loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
      },
      {
        path: 'account',
        loadChildren: () => import('./account/account.module').then(m => m.AccountModule)
      },
      {
        path: 'forbidden',
        component: NavigationMainContainerComponent
      },
      {
        path: '',
        component: NavigationMainContainerComponent,
        canActivate: [ForbiddenGuard],
        children: [
          {
            path: '',
            redirectTo: 'dashboard',
            pathMatch: 'full'
          },
          {
            path: 'dashboard',
            loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
            canLoad: [ScopeGuard]
          },
          {
            path: 'evse',
            loadChildren: () => import('./b2c-view/b2c.module').then(m => m.B2cModule),
            canLoad: [ScopeGuard]
          },
          {
            path: 'pairing',
            loadChildren: () => import('./b2c-pairing/pairing.module').then(m => m.PairingModule),
            canLoad: [ScopeGuard]
          },
          {
            path: 'transaction',
            loadChildren: () => import('./b2c-session/session.module').then(m => m.SessionModule),
            canLoad: [ScopeGuard]
          },
          {
            path: 'badge',
            loadChildren: () => import('./b2c-badge/badge.module').then(m => m.BadgeModule),
            canLoad: [ScopeGuard]
          },
          {
            path: 'task',
            loadChildren: () => import('./task/task.module').then(m => m.TaskModule),
            canLoad: [ScopeGuard]
          },
          {
            path: 'massFirmwareUpdate',
            loadChildren: () =>
              import('./mass-firmware-update/mass-firmware-update.module').then(
                m => m.MassFirmwareUpdateModule
              ),
            canLoad: [ScopeGuard]
          },
          {
            path: 'reporting',
            loadChildren: () => import('./reporting/reporting.module').then(m => m.ReportingModule),
            canLoad: [ScopeGuard]
          },
          {
            path: 'settings',
            loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule)
          },
          {
            path: 'admin',
            loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
            canLoad: [ScopeGuard]
          },
          {
            path: 'user',
            loadChildren: () => import('./user/user.module').then(m => m.UserModule),
            canLoad: [ScopeGuard]
          },
          {
            path: '',
            loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
            canActivate: [NavigationGuard]
          }
        ]
      }
    ]
  },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(ROUTES, {
      enableTracing: false
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
