import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TranslationGuard implements CanActivate {
  private loaded = false;
  private loaded$ = new Subject<boolean>();

  constructor(translate: TranslateService) {
    translate.defaultLang = 'fr-FR';
    translate.getTranslation('fr-FR').subscribe(() => {
      this.loaded = true;
      this.loaded$.next(true);
      translate.use('fr-FR');
    });
  }

  canActivate(): boolean | Observable<boolean> {
    return this.loaded ? true : this.loaded$;
  }
}
