import { Component, EventEmitter, Input, Output } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { TaskDisplay } from '../../task.service';

@Component({
  selector: 'app-task-display-header',
  templateUrl: './task-display-header.component.html',
  styleUrls: ['./task-display-header.component.scss']
})
export class TaskDisplayHeaderComponent {
  @Input() display: TaskDisplay;
  @Output() taskChange = new EventEmitter();

  constructor(public translate: TranslateService) {}
}
