import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { AppNavigationService } from '../navigation.service';
import { AuthenticationService } from '@app/auth/auth.service';

@Injectable({ providedIn: 'root' })
export class NavigationGuard implements CanActivate {
  constructor(
    private router: Router,
    private oauth: AuthenticationService,
    private navService: AppNavigationService
  ) {}

  canActivate(): Observable<UrlTree> {
    return this.navService.navItems.pipe(
      take(1),
      map(items => {
        if (items.length === 0 || !this.oauth.hasValidAccessToken()) {
          return this.router.createUrlTree(['/', 'login']);
        } else {
          return this.router.createUrlTree([items[0].path]);
        }
      })
    );
  }
}
