export interface B2cLocale {
  display: string;
  value: string;
}

export const ALL_LOCALE_OPTIONS: B2cLocale[] = [
  { display: 'FR', value: 'fr-FR' },
  { display: 'EN (US)', value: 'en-US' },
  { display: 'DE', value: 'de-DE' },
  { display: 'ES', value: 'es-ES' },
  { display: 'IT', value: 'it-IT' },
  { display: 'NL', value: 'nl-NL' }
];

export const DEFAULT_LOCALE = { display: 'EN', value: 'en-US' };
export const LOCALE_KEY = 'evHub-locale-key';
