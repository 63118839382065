import { animate, animateChild, query, style, transition, trigger } from '@angular/animations';
import { AnimationCurves, AnimationDurations } from '@angular/material/core';

export const searchInOut = trigger('searchInOut', [
  transition(':enter', [
    style({ opacity: 0, transform: 'scale(0)', width: '40px' }),
    animate(
      `${AnimationDurations.ENTERING} ${AnimationCurves.STANDARD_CURVE}`,
      style({ opacity: 1, transform: 'scale(1)' })
    ),
    animate(
      `${AnimationDurations.COMPLEX} ${AnimationCurves.DECELERATION_CURVE}`,
      style({ width: '100%' })
    ),
    query('@fadeInOut', animateChild(), { optional: true })
  ]),

  transition(':leave', [
    query('@fadeInOut', animateChild(), { optional: true }),
    style({ opacity: 1, transform: 'scale(1)', width: '100%' }),
    animate(
      `${AnimationDurations.COMPLEX} ${AnimationCurves.ACCELERATION_CURVE}`,
      style({ width: '40px' })
    ),
    animate(
      `${AnimationDurations.EXITING} ${AnimationCurves.STANDARD_CURVE}`,
      style({ opacity: 0, transform: 'scale(0)' })
    )
  ])
]);
