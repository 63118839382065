import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarConfig } from '@angular/material/snack-bar';

export type SnackbarType = 'warning' | 'error' | 'success';

export interface SnackbarData {
  message: string;
  type: SnackbarType;
  closeSnackBar?: any;
}

export type SnackBarConfig = Omit<MatSnackBarConfig, 'data'>;

@Component({
  templateUrl: './snack-bar-message.component.html',
  styleUrls: ['./snack-bar-message.component.scss']
})
export class SnackBarMessageComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: SnackbarData) {}
}
