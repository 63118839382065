import { NgZone } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppNavigationService } from '@app/navigation';
import { FilterAutocompleteOption, FilterOption, SearchFilter } from '@app/shared';

export const INPUT_FILTER_OPTIONS: FilterOption[] = [
  { name: 'task.Operation', param: 'operation', type: 'option' },
  { name: 'task.Target', param: 'target', type: 'option' },
  { name: 'task.Task ID', param: 'id' },
  { name: 'task.Mass Firmware Update', param: 'massFirmwareUpdateId' }
];

export const FEATURE_OPTIONS: FilterOption[] = [
  { name: 'task.filter.Ongoing', param: 'pending' },
  { name: 'task.filter.Finished', param: 'finished' }
];

const TASK_OPERATION_OPTIONS: FilterAutocompleteOption[] = [
  { display: 'task.operation.ENABLE_MAINTENANCE', value: 'ENABLE_MAINTENANCE', translate: true },
  { display: 'task.operation.DISABLE_MAINTENANCE', value: 'DISABLE_MAINTENANCE', translate: true },
  { display: 'task.operation.HARD_RESET', value: 'HARD_RESET', translate: true },
  { display: 'task.operation.LED_BLINK', value: 'LED_BLINK', translate: true },
  { display: 'task.operation.UPDATE_FIRMWARE', value: 'UPDATE_FIRMWARE', translate: true },
  { display: 'task.operation.ENABLE_RFID_READER', value: 'ENABLE_RFID_READER', translate: true },
  { display: 'task.operation.DISABLE_RFID_READER', value: 'DISABLE_RFID_READER', translate: true },
  { display: 'task.operation.TOGGLE_RFID_ADMIN', value: 'TOGGLE_RFID_ADMIN', translate: true }
];

export class TaskFilter extends SearchFilter {
  inputFilters: FilterOption[] = INPUT_FILTER_OPTIONS.map(f => Object.assign({}, f));
  features: FilterOption[] = FEATURE_OPTIONS.map(f => Object.assign({}, f));

  private evseOptions: FilterAutocompleteOption[];

  constructor(private ngZone: NgZone, private navService: AppNavigationService) {
    super();
  }

  getInputAutoCompleteOptions(selected: FilterOption) {
    if (selected) {
      switch (selected.param) {
        case 'target':
          if (!this.evseOptions) {
            return this.loadEvseAutoCompleteOptions();
          } else {
            return of(this.evseOptions);
          }
        case 'operation':
          return of(TASK_OPERATION_OPTIONS);
        default:
          return undefined;
      }
    }
    return undefined;
  }

  onFeatureChipClick(filter: FilterOption) {
    filter.value = filter.value ? undefined : true;
    if (filter.param === 'pending' && !filter.value) {
      this.removeFeatureFilterByParam('pending');
    }
    if (filter.param === 'finished' && !filter.value) {
      this.removeFeatureFilterByParam('finished');
    }
  }

  applyFeatureFilter(feature: FilterOption, params: any) {
    switch (feature.param) {
      case 'pending':
        params.pending = 'true';
        break;
      case 'finished':
        params.finished = 'true';
        break;
      default:
        params[feature.param] = feature.value;
    }
  }

  parseOptionFilterParam(
    filter: FilterOption,
    value: any
  ): FilterAutocompleteOption | Observable<FilterAutocompleteOption> {
    let $options: Observable<FilterAutocompleteOption[]>;
    if (filter.param === 'target') {
      $options = this.evseOptions ? of(this.evseOptions) : this.loadEvseAutoCompleteOptions();
    }
    if (filter.param === 'operation') {
      return TASK_OPERATION_OPTIONS.find(o => o.value === value);
    }
    if ($options) {
      return $options.pipe(map(options => options.find(o => o.value === value)));
    }
    return undefined;
  }

  parseFeatureParam(key: string, value: any): string {
    if (key === 'pending' && value === 'true') {
      return 'pending';
    }
    if (key === 'finished' && value === 'true') {
      return 'finished';
    }
    return undefined;
  }

  private loadEvseAutoCompleteOptions(): Observable<FilterAutocompleteOption[]> {
    return new Observable(observer => {
      if (this.evseOptions) {
        observer.next(this.evseOptions);
        observer.complete();
      } else {
        this.ngZone.runOutsideAngular(() => {
          this.navService.getAllB2cEvses().subscribe(evses => {
            this.evseOptions = evses.items.map(evse => {
              return { display: evse._id, value: evse._id };
            });
            this.ngZone.run(() => {
              observer.next(this.evseOptions);
              observer.complete();
            });
          });
        });
      }
    });
  }
}
